import React from "react";

const Caregiver = (props) => {
  return (
    <svg
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.81 30.43"
    >
      <path
        d="M17.9,10c-2.77,0-5.02-2.25-5.02-5.02S15.14-.04,17.9-.04s5.02,2.25,5.02,5.02-2.25,5.02-5.02,5.02ZM17.9,2.21c-1.53,0-2.77,1.24-2.77,2.77s1.24,2.77,2.77,2.77,2.77-1.24,2.77-2.77-1.24-2.77-2.77-2.77Z"
        fill={props.color}
      />
      <path
        d="M29.44,9.98c-2.05,0-3.72-1.67-3.72-3.72s1.67-3.72,3.72-3.72,3.72,1.67,3.72,3.72-1.67,3.72-3.72,3.72ZM29.44,4.79c-.81,0-1.47.66-1.47,1.47s.66,1.47,1.47,1.47,1.47-.66,1.47-1.47-.66-1.47-1.47-1.47Z"
        fill={props.color}
      />
      <path
        d="M6.37,9.98c-2.05,0-3.72-1.67-3.72-3.72s1.67-3.72,3.72-3.72,3.72,1.67,3.72,3.72-1.67,3.72-3.72,3.72ZM6.37,4.79c-.81,0-1.47.66-1.47,1.47s.66,1.47,1.47,1.47,1.47-.66,1.47-1.47-.66-1.47-1.47-1.47Z"
        fill={props.color}
      />
      <path
        d="M17.91,30.43c-.07,0-.14,0-.2,0-4.09-.11-7.42-3.66-7.42-7.92v-7.07c0-1.45,1.18-2.62,2.62-2.62h9.98c1.45,0,2.63,1.18,2.63,2.63v7.37c0,2.07-.82,4.01-2.3,5.46-1.44,1.4-3.32,2.16-5.31,2.16ZM12.91,15.06c-.21,0-.38.17-.38.38v7.07c0,3.05,2.34,5.59,5.23,5.67,1.46.07,2.84-.5,3.89-1.52,1.04-1.02,1.62-2.38,1.62-3.84v-7.37c0-.21-.17-.38-.38-.38h-9.98Z"
        fill={props.color}
      />
      <path
        d="M29.44,27.69c-.06,0-.11,0-.17,0-.83-.02-1.64-.21-2.4-.56-.56-.26-.81-.93-.55-1.49.26-.56.93-.81,1.49-.55.48.22.99.34,1.52.35,1.09.02,2.18-.38,2.98-1.17.8-.78,1.25-1.83,1.25-2.95v-5.96s-.04-.09-.09-.09h-5.74c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h5.74c1.29,0,2.34,1.05,2.34,2.34v5.96c0,1.73-.68,3.35-1.92,4.56-1.2,1.17-2.77,1.81-4.44,1.81Z"
        fill={props.color}
      />
      <path
        d="M6.37,27.69c-.06,0-.11,0-.17,0C2.78,27.6,0,24.63,0,21.08v-5.71C0,14.07,1.05,13.03,2.34,13.03h5.74c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12H2.34s-.09.04-.09.09v5.71c0,2.35,1.8,4.3,4.01,4.36.63.02,1.25-.11,1.82-.37.56-.26,1.23-.01,1.49.56.26.56,0,1.23-.56,1.49-.83.38-1.72.57-2.64.57Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Caregiver;
