import React from "react";

const EditProfileIcn = (props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.35 34.06"
    >
      <path
        d="M16.17,17.51c-4.83,0-8.75-3.93-8.75-8.75S11.35,0,16.17,0s8.75,3.93,8.75,8.75-3.93,8.75-8.75,8.75ZM16.17,2.25c-3.59,0-6.5,2.92-6.5,6.5s2.92,6.5,6.5,6.5,6.5-2.92,6.5-6.5-2.92-6.5-6.5-6.5Z"
        fill={props.color}
      />
      <path
        d="M31.22,34.06H1.12C.5,34.06,0,33.56,0,32.94,0,25.66,7.26,19.74,16.17,19.74s16.17,5.92,16.17,13.2c0,.62-.5,1.12-1.12,1.12ZM2.32,31.81h27.7c-.72-5.51-6.66-9.82-13.85-9.82S3.04,26.3,2.32,31.81Z"
        fill={props.color}
      />
    </svg>
  );
};

export default EditProfileIcn;
