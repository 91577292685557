import {
    FEATURE,
    FEATURE_SET_SUCCESS,
    FEATURE_WARD_SET_SUCCESS
} from "../actionType";


const INITIAL_STATE = {
};

const feature = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case FEATURE:

            return {
                ...state,
            };
        case FEATURE_SET_SUCCESS:

            const featureFlat = [];
            if (action.payload && action.payload.length) {
                action.payload.forEach(e => {
                    
                    if (e.disableFeatures) {
                        e.disableFeatures.forEach(f => {
                            featureFlat.push({
                                name: f,
                                isActive: false,
                                enableFeatures: [],
                                disableFeatures: []
                            });
                        })

                    }else{
                        //featureFlat.push(e);
                        featureFlat.push({
                            name: e,
                            isActive: false,
                            enableFeatures: [],
                            disableFeatures: []
                        });
                    }


                })
            }

            return {
                ...state,
                featuretoggle: featureFlat,
                featuretoggleFlat: featureFlat
            };

        case FEATURE_WARD_SET_SUCCESS:
            return {
                ...state,
                featureWardtoggle: action.payload
            };
        default:
            return state
    }
};
export default feature;