import React from "react";

const TransactionTransfer = (props) => {
  return (
    <svg
      viewBox="0 0 110 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.4824 8.99759V8.99993L53.5 8.99993C53.6382 8.99993 53.7761 9.00093 53.9139 9.00292C39.2691 9.30511 27.4902 21.2712 27.4902 35.9884C27.4902 39.7367 28.2543 43.3065 29.6351 46.5506H18.9116V37.4913C18.9116 36.1417 17.268 35.479 16.3319 36.4512L0.419386 52.9757C-0.139795 53.5564 -0.139795 54.4753 0.419386 55.056L16.3319 71.5805C17.268 72.5526 18.9116 71.89 18.9116 70.5404V63.0453L56.3996 63.0453V62.9858C56.1006 62.9952 55.8005 63 55.4992 63C55.1393 63 54.7811 62.9932 54.4246 62.9798L54.4816 62.9798C69.3885 62.9798 81.473 50.8954 81.473 35.9884C81.473 32.2656 80.7193 28.7188 79.3561 25.4923H90.9704V34.5516C90.9704 35.9012 92.614 36.5639 93.5501 35.5917L109.463 19.0672C110.022 18.4865 110.022 17.5676 109.463 16.9869L93.5501 0.462406C92.614 -0.509747 90.9704 0.152924 90.9704 1.50253V8.99759L54.6503 8.99759C54.5941 8.99724 54.5379 8.99707 54.4816 8.99707C54.4253 8.99707 54.3691 8.99724 54.3129 8.99759H53.4824ZM54.4816 14.9952C42.8873 14.9952 33.4883 24.3942 33.4883 35.9884C33.4883 47.5827 42.8873 56.9817 54.4816 56.9817C66.0759 56.9817 75.4749 47.5827 75.4749 35.9884C75.4749 24.3942 66.0759 14.9952 54.4816 14.9952Z"
        fill={props.color}
      />
      <path
        d="M54.0415 49.904V23.1226H56.8906V49.904H54.0415ZM55.0672 46.827C53.4717 46.827 51.9427 46.6181 50.4801 46.2002C49.0176 45.7633 47.84 45.203 46.9473 44.5192L48.5143 41.0433C49.369 41.6511 50.3757 42.1545 51.5343 42.5534C52.7119 42.9332 53.899 43.1232 55.0957 43.1232C56.0074 43.1232 56.7386 43.0377 57.2895 42.8668C57.8593 42.6768 58.2771 42.4204 58.5431 42.0975C58.809 41.7746 58.9419 41.4042 58.9419 40.9864C58.9419 40.4545 58.733 40.0367 58.3151 39.7328C57.8973 39.4099 57.3464 39.1534 56.6627 38.9635C55.9789 38.7546 55.2191 38.5646 54.3834 38.3937C53.5667 38.2037 52.7404 37.9758 51.9047 37.7099C51.0879 37.444 50.3377 37.1021 49.6539 36.6842C48.9701 36.2664 48.4098 35.7155 47.9729 35.0318C47.5551 34.348 47.3461 33.4743 47.3461 32.4106C47.3461 31.271 47.65 30.2358 48.2578 29.3051C48.8846 28.3554 49.8153 27.6051 51.05 27.0543C52.3036 26.4845 53.8706 26.1996 55.751 26.1996C57.0046 26.1996 58.2392 26.3515 59.4548 26.6554C60.6704 26.9403 61.7435 27.3772 62.6742 27.966L61.2497 31.4704C60.319 30.9386 59.3883 30.5492 58.4576 30.3023C57.5269 30.0364 56.6152 29.9034 55.7225 29.9034C54.8297 29.9034 54.0985 30.0079 53.5287 30.2168C52.9588 30.4257 52.5505 30.7011 52.3036 31.043C52.0566 31.3659 51.9332 31.7458 51.9332 32.1827C51.9332 32.6955 52.1421 33.1134 52.56 33.4363C52.9778 33.7402 53.5287 33.9871 54.2124 34.177C54.8962 34.367 55.6465 34.5569 56.4632 34.7468C57.299 34.9368 58.1252 35.1552 58.9419 35.4021C59.7777 35.6491 60.5374 35.9815 61.2212 36.3993C61.905 36.8172 62.4558 37.368 62.8737 38.0518C63.3105 38.7356 63.529 39.5998 63.529 40.6445C63.529 41.7651 63.2156 42.7908 62.5888 43.7215C61.962 44.6522 61.0218 45.4024 59.7682 45.9723C58.5336 46.5421 56.9666 46.827 55.0672 46.827Z"
        fill={props.color}
      />
    </svg>
  );
};

export default TransactionTransfer;
