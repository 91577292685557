import React from "react";

const TransactionDeposits = (props) => {
  return (
    <svg
      viewBox="0 0 104 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 74C7.89543 74 7 74.8954 7 76V82H2C0.895431 82 0 82.8954 0 84V91C0 92.1046 0.895432 93 2 93H102C103.105 93 104 92.1046 104 91V84C104 82.8954 103.105 82 102 82H98V76C98 74.8954 97.1046 74 96 74H9Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38 18C38 10.268 44.268 4 52 4C59.732 4 66 10.268 66 18C66 25.732 59.732 32 52 32C44.268 32 38 25.732 38 18ZM52 0C42.0589 0 34 8.05887 34 18C34 18.2564 34.0054 18.5116 34.016 18.7654L8.33789 27.7799C8.26953 27.8039 8.20312 27.8312 8.13867 27.8615L7.99805 27.9362C7.91797 27.9821 7.84375 28.0329 7.77344 28.0885C7.68555 28.1559 7.60547 28.2306 7.53125 28.3112C7.19727 28.6725 7 29.1525 7 29.6666V37.0001C7 38.1046 7.89453 39.0001 9 39.0001H15V70.0001H25V39.0001H36V70.0001H43H47H68V39.0001H79V70.0001H90V39.0001H94C95.1055 39.0001 96 38.1046 96 37.0001V29.6666C96 28.8097 95.4551 28.0485 94.6426 27.7731L69.9469 19.3926C69.9821 18.933 70 18.4686 70 18C70 8.05887 61.9411 0 52 0ZM58 38.0002H47V57.0002H41.4805C40.5805 57.0002 40.1386 58.0962 40.7869 58.7205L51.8068 69.3322C52.194 69.7051 52.8068 69.7051 53.194 69.3322L64.2139 58.7205C64.8622 58.0962 64.4203 57.0002 63.5203 57.0002H58V38.0002Z"
        fill={props.color}
      />
      <path
        d="M51.134 28V8H53.1959V28H51.134ZM51.8763 25.7021C50.7216 25.7021 49.6151 25.5461 48.5567 25.234C47.4983 24.9078 46.646 24.4894 46 23.9787L47.134 21.383C47.7526 21.8369 48.4811 22.2128 49.3196 22.5106C50.1718 22.7943 51.0309 22.9362 51.8969 22.9362C52.5567 22.9362 53.0859 22.8723 53.4845 22.7447C53.8969 22.6028 54.1993 22.4113 54.3918 22.1702C54.5842 21.9291 54.6804 21.6525 54.6804 21.3404C54.6804 20.9433 54.5292 20.6312 54.2268 20.4043C53.9244 20.1631 53.5258 19.9716 53.0309 19.8298C52.5361 19.6738 51.9863 19.5319 51.3814 19.4043C50.7904 19.2624 50.1924 19.0922 49.5876 18.8936C48.9966 18.695 48.4536 18.4397 47.9588 18.1277C47.4639 17.8156 47.0584 17.4043 46.7423 16.8936C46.4399 16.383 46.2887 15.7305 46.2887 14.9362C46.2887 14.0851 46.5086 13.3121 46.9485 12.617C47.4021 11.9078 48.0756 11.3475 48.9691 10.9362C49.8763 10.5106 51.0103 10.2979 52.3711 10.2979C53.2784 10.2979 54.1718 10.4113 55.0515 10.6383C55.9313 10.8511 56.7079 11.1773 57.3814 11.617L56.3505 14.234C55.677 13.8369 55.0034 13.5461 54.3299 13.3617C53.6564 13.1631 52.9966 13.0638 52.3505 13.0638C51.7045 13.0638 51.1753 13.1418 50.7629 13.2979C50.3505 13.4539 50.055 13.6596 49.8763 13.9149C49.6976 14.156 49.6082 14.4397 49.6082 14.766C49.6082 15.1489 49.7595 15.461 50.0619 15.7021C50.3643 15.9291 50.7629 16.1135 51.2577 16.2553C51.7526 16.3972 52.2955 16.539 52.8866 16.6809C53.4914 16.8227 54.0893 16.9858 54.6804 17.1702C55.2852 17.3546 55.8351 17.6028 56.3299 17.9149C56.8247 18.227 57.2234 18.6383 57.5258 19.1489C57.8419 19.6596 58 20.305 58 21.0851C58 21.922 57.7732 22.6879 57.3196 23.383C56.866 24.078 56.1856 24.6383 55.2784 25.0638C54.3849 25.4894 53.2509 25.7021 51.8763 25.7021Z"
        fill={props.color}
      />
    </svg>
  );
};

export default TransactionDeposits;
