import React from "react";

const Shopping = (props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36.96 34.79"
    >
      <path
        d="M32.65,34.79h-14.99c-3.67,0-4.31-1.78-4.31-5.51v-5.92c0-3.73.65-5.51,4.31-5.51h14.99c3.67,0,4.31,1.78,4.31,5.51v5.92c0,3.73-.65,5.51-4.31,5.51ZM17.66,20.11c-1.75,0-2.06,0-2.06,3.26v5.92c0,3.26.31,3.26,2.06,3.26h14.99c1.75,0,2.06,0,2.06-3.26v-5.92c0-3.26-.31-3.26-2.06-3.26h-14.99Z"
        fill={props.color}
      />
      <path
        d="M9.24,28.12h-2.83C1.44,28.12,0,26.61,0,21.41v-7.54C0,8.68,1.44,7.18,6.41,7.18h17.48c4.69,0,6.25,1.4,6.4,5.76.02.62-.46,1.14-1.08,1.16-.62.04-1.14-.46-1.16-1.08-.11-3.15-.61-3.59-4.15-3.59H6.41c-3.73,0-4.16.46-4.16,4.45v7.54c0,3.99.43,4.45,4.16,4.45h2.83c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M19.81,15.43c-.62,0-1.12-.5-1.12-1.12V5.63c0-2.33-1.78-3.38-3.55-3.38s-3.55,1.04-3.55,3.38v8.68c0,.62-.5,1.12-1.12,1.12s-1.12-.5-1.12-1.12V5.63c0-3.7,2.92-5.63,5.8-5.63s5.8,1.93,5.8,5.63v8.68c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M25.15,26.95c-1.53,0-2.96-.61-4.03-1.72-1.16-1.21-1.77-2.91-1.77-4.91,0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12c0,3.03,1.78,4.38,3.55,4.38s3.55-1.35,3.55-4.38c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12c0,4.35-2.92,6.63-5.8,6.63Z"
        fill={props.color}
      />
    </svg>
  );
};

export { Shopping };
