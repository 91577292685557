import React from "react";

const FamilyPeople = (props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 35.18 35.42"
    >
      <path
        d="M26.95,8.49c-2.34,0-4.25-1.9-4.25-4.25s1.9-4.25,4.25-4.25,4.25,1.9,4.25,4.25-1.9,4.25-4.25,4.25ZM26.95,2.25c-1.12,0-2,.88-2,2s.88,2,2,2,2-.88,2-2-.88-2-2-2Z"
        fill={props.color}
      />
      <path
        d="M30.07,35.42h-4.51c-.62,0-1.12-.5-1.12-1.12v-12.65c0-1.61-.93-3.15-2.32-3.85-.5-.25-.74-.83-.56-1.36l1.39-4.16c.47-1.66,2.13-2.85,4.01-2.85h.17c1.88,0,3.54,1.19,4.03,2.89l3.97,12.09c.11.34.05.72-.16,1.01-.21.29-.55.46-.91.46h-2.86v8.41c0,.62-.5,1.12-1.12,1.12ZM26.69,33.17h2.26v-8.41c0-.62.5-1.12,1.12-1.12h2.43l-3.5-10.66c-.22-.77-1.01-1.31-1.88-1.31h-.17c-.87,0-1.66.53-1.86,1.26l-1.12,3.36c1.68,1.21,2.72,3.21,2.72,5.35v11.53Z"
        fill={props.color}
      />
      <path
        d="M17.42,17.48c-1.99,0-3.55-1.56-3.55-3.55s1.56-3.55,3.55-3.55,3.55,1.56,3.55,3.55-1.56,3.55-3.55,3.55ZM17.42,12.62c-.77,0-1.3.54-1.3,1.3s.54,1.3,1.3,1.3,1.3-.54,1.3-1.3-.54-1.3-1.3-1.3Z"
        fill={props.color}
      />
      <path
        d="M6.5,8.49c-2.34,0-4.24-1.9-4.24-4.25S4.16,0,6.5,0s4.25,1.9,4.25,4.25-1.9,4.25-4.25,4.25ZM6.5,2.25c-1.12,0-1.99.88-1.99,2s.88,2,1.99,2,2-.88,2-2-.88-2-2-2Z"
        fill={props.color}
      />
      <path
        d="M19.85,35.42h-4.85c-.62,0-1.12-.5-1.12-1.12v-5.11h-.43c-.62,0-1.12-.5-1.12-1.12v-6.24c0-1.92,1.63-3.55,3.55-3.55h3.12c1.92,0,3.55,1.63,3.55,3.55v6.24c0,.62-.5,1.12-1.12,1.12h-.44v5.11c0,.62-.5,1.12-1.12,1.12ZM16.12,33.17h2.6v-5.11c0-.62.5-1.12,1.12-1.12h.44v-5.11c0-.61-.56-1.3-1.3-1.3h-3.12c-.61,0-1.3.56-1.3,1.3v5.11h.43c.62,0,1.12.5,1.12,1.12v5.11Z"
        fill={props.color}
      />
      <path
        d="M9.19,35.42H3.55c-.62,0-1.12-.5-1.12-1.12v-9.97h-1.3C.5,24.33,0,23.83,0,23.2v-9.53C0,11.33,1.91,9.43,4.24,9.43h4.68c2.34,0,4.25,1.9,4.25,4.24v2.81c0,.39-.2.75-.53.96-.09.06-2.32,1.52-2.32,4.73v12.12c0,.62-.5,1.12-1.12,1.12ZM4.68,33.17h3.39v-11c0-3.44,1.89-5.45,2.85-6.25v-2.25c0-1.12-.88-1.99-2-1.99h-4.68c-1.12,0-1.99.88-1.99,1.99v8.41h1.3c.62,0,1.12.5,1.12,1.12v9.97Z"
        fill={props.color}
      />
    </svg>
  );
};

export default FamilyPeople;
