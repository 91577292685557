import React from "react";
import { useLocation } from "react-router";
import FamilyPeople from "./icons/settings/FamilyPeople";
import Timeline from "./icons/Timeline";
import Education from "./icons/Education";
import Wallet from "./icons/Wallet";
import { Shopping } from "./icons/Shopping";
import InvestmentDashboard from "./icons/InvestmentDashboard";
import Research from "./icons/Research";
import Watchlist from "./icons/Watchlist";
import Report from "./icons/Report";

const Bottom = (props) => {
  const location = useLocation();
  const isVisible = props.isMobileNav;
  const activeColor = props.state.theme.values.primaryColor;
  const defaultColor = props.state.theme.values.tertiaryGrayColor;

  return (

    <>
      {props.investmentSidebar === false ?
        <div className={`bottom-navigation-bar ${isVisible ? '' : 'hide'}`}>
          <div className="inner-bar">
            <ul>
              <li onClick={() => props.history.push('/parent/dashboard')} className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <FamilyPeople color={location.pathname === "/parent/dashboard" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/timeline')} className={`${location.pathname === "/parent/timeline" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Timeline color={location.pathname === "/parent/timeline" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/education')} className={`${location.pathname === "/parent/education" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Education color={location.pathname === "/parent/education" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/wallet')} className={`${location.pathname === "/parent/wallet" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Wallet color={location.pathname === "/parent/wallet" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/shopping')} className={`${location.pathname === "/parent/shopping" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Shopping color={location.pathname === "/parent/shopping" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
            </ul>
          </div>
        </div>
        : 
        <div className={`bottom-navigation-bar ${isVisible ? '' : 'hide'}`}>
          <div className="inner-bar">
            <ul>
              <li onClick={() => props.history.push('/parent/investments/dashboard')} className={`${location.pathname === "/parent/investments/dashboard" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <InvestmentDashboard color={location.pathname === "/parent/investments/dashboard" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/investments/timeline')} className={`${location.pathname === "/parent/investments/timeline" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Timeline color={location.pathname === "/parent/investments/timeline" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/investments/research')} className={`${location.pathname === "/parent/investments/research" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Research color={location.pathname === "/parent/investments/research" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/investments/watchlist')} className={`${location.pathname === "/parent/investments/watchlist" ? 'active' : ''}`}>

                <span className="svgIcon">
                  <Watchlist color={location.pathname === "/parent/investments/watchlist" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/parent/investments/reports')} className={` ${location.pathname === "/parent/investments/reports" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Report color={location.pathname === "/parent/investments/reports" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
            </ul>
          </div>
        </div>
        }

    </>
  );
};

export default Bottom;
