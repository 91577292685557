import React, { Suspense, useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import Sidebar from "./Components/Sidebar";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import LoaderPost from "../../Components/Loader";
import * as constants from "../../Utils/constants";
import InvestmentSidebar from "./Components/Sidebar/InvestmentSidebar";
import { Link } from "react-router-dom";
import { showModal } from "../../Utils";
import Bottom from "./Components/Sidebar/Bottom";
import { THEME_CALL_WARD } from "../../Components/Themes/Theme";
import { BASE_URL } from "../../Utils/url";
import InfoCircle from "../Authenticated/Components/Sidebar/icons/InfoCircle";

const Loader = () => (
  <></>
)

const AuthenticatedLayout = (props) => {
  const { t } = useTranslation();
  const { route } = props;
  const location = useLocation();

  const [user, setuser] = useState();
  const [investmentAccountData, setInvestmentAccountData] = useState();
  const [headerTitle, setheaderTitle] = useState("")
  const [isNotification, setisNotification] = useState(true)
  const [isHeaderIcons, setisHeaderIcons] = useState(true)
  const [isSidebar, setisSidebar] = useState(true)
  const [isMobileNav, setisMobileNav] = useState(false)
  const [helpIcon, sethelpIcon] = useState(true)
  const [languageIcon, setlanguageIcon] = useState(true)
  const [investmentSidebar, setinvestmentSidebar] = useState(false)
  const [notificationModal, setnotificationModal] = useState(null);
  const [pageClass, setpageClass] = useState(``)
  //const [isBank,setisBank] = useState(sessionStorage.getItem("__bank"));
  const [showHeader, setshowHeader] = useState(window.sessionStorage.getItem("__bank"));
  const [chore, setchore] = useState({
    pending: 0,
    amount: 0
  })
  const [investmentBodyColor, setinvestmentBodyColor] = useState('')

  useEffect(() => {
    setuser(props.state.user);
  }, [props && props.state && props.state.user])

  useEffect(() => {
    if(props.state.user){
      getWardDisabledFeatures(props.state.user);
    }
    
  }, [props?.state?.user])

  useEffect(() => {
    if (props.state && props.state.theme.values) {
      //window.document.title = props.state.theme.values.tenantFullName;
      THEME_CALL_WARD(props.state.theme.values);
      setshowHeader(props.state.theme.values.showHeader)
    }

  }, [props.state])

  useEffect(() => {
      calluser();
  },[])

  useEffect(() => {
    if (props.state.theme.values.primaryWardColor) {
      generateInvestmentBodyColor();
    }
  },[props && props.state && props.state.theme])

  useEffect(() => {
      if(investmentBodyColor){
        getInvestmentAccount();
      }
  },[investmentBodyColor])

  useEffect(() => {

    if (location.pathname) {
      logoutAfter();
      let filterRoutes = route.routes.filter(e => e.path === location.pathname);
      if (filterRoutes.length === 0) {
        filterRoutes = route.routes.filter(e => (e.path) ? location.pathname.indexOf(e.path.split(":")[0]) !== -1 : false);
      }

      if (filterRoutes.length > 0 && filterRoutes[0].params) {
        getFeatureFlag();
        setheaderTitle(filterRoutes[filterRoutes.length - 1].params.title);
        setisMobileNav(filterRoutes[0].isMobileNav);
        setisNotification(filterRoutes[0].params.notificationBell);
        setlanguageIcon(filterRoutes[0].params.languageIcon);
        sethelpIcon(filterRoutes[0].params.helpIcon);
        setisHeaderIcons((filterRoutes[0].params.headerIcons === false) ? filterRoutes[0].params.headerIcons : true);
        setisSidebar((filterRoutes[0].params.sidebar === false) ? filterRoutes[0].params.sidebar : true);
        setinvestmentSidebar((filterRoutes[0].params.investmentSidebar === true) ? filterRoutes[0].params.investmentSidebar : false);
        setpageClass('page' + filterRoutes[filterRoutes.length - 1].path.replace(/:/g, '').replace(/\//g, '-'));
        //setisBank(!filterRoutes[0].showHeader)
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`page-register`));
        getInvestmentAccount();
        fetchChores();
      } else {
        setheaderTitle(``);
      }
    }

  }, [location.pathname])

  useEffect(() => {
    if (notificationModal === true) {
      showModal("notificationModal");
      // setnotificationModal(false);
    }
    if (notificationModal === false) {
      setnotificationModal(true);
    }
  }, [notificationModal])

  useEffect(() => {
    sessionStorage.setItem('loginType', 'child')
  }, [])

  const calluser=()=>{
    props._list(constants.V6USERS + '?v=m2', null, (res) => {
      window.sessionStorage.setItem('showMazoolaPay', res.showMazoolaPay)
    });
  }

  const getWardDisabledFeatures = (user) => {
    props._featureWard(constants.WARDDISABLEDFEATURES(user.self.userId), null, (res) => {
      
    })
  }

  const getFeatureFlag = () => {
    if (props.state && props.state.crud.featuretoggle) { return }
    props._feature(constants.FEATURE_FLAG_V2, null, res => {

    }, err => { }, false)
  }

  const generateInvestmentBodyColor = () => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.state.theme.values.primaryWardColor);
    if(!result){return}
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);
    const color = `rgba(${r}, ${g}, ${b}, 0.125)`;
    setinvestmentBodyColor(color)
  }

  const toggleSidebar = () => {
    document.querySelector('.child-left-sidebar').classList.toggle('active');
  }

  const logoutAfter = () => {
    var idleInterval;

    if (idleInterval) {
      clearInterval(idleInterval);
    }

    document.removeEventListener(`mousemove`, () => { });
    document.removeEventListener(`keypress`, () => { });

    var idleTime = 0;

    document.addEventListener(`mousemove`, () => {
      idleTime = 0;
    })

    document.addEventListener(`keypress`, () => {
      idleTime = 0;
    })

    const timerIncrement = () => {
      idleTime = idleTime + 1;
    }

    idleInterval = setInterval(timerIncrement, 60000);

  }

  const fetchChores = () => {
    props._list(constants.CHILD_CHORES, null, (res) => {

      let amount = 0;
      let pending = 0;

      const now = new Date();
      res.forEach(e => {
        e.choreInstances.forEach(f => {
          const date = new Date(f.dueBy);
          if (date >= now) {
            if (f.completionStatus === 0) {
              ++pending;
              amount = amount + e.amount;
            }
          }
        })
      })

      setchore({
        pending: pending,
        amount: amount
      })

    })
  }

  const getInvestmentAccount = () => {
    props._investment_account(`${constants.INVESTMENTS_CREATE}?wardId=${sessionStorage.getItem('userId')}`, null, (res) => {
      let data = res;
      // const d = {
      //   liveAccount: data.liveAccount,
      //   practiceAccount: data.practiceAccount
      // };
      const d = res[0];
      // if (res.liveAccount.isSuspended === false) {
      //   sessionStorage.setItem('practiceMode', false);
      // }
      // else {
      //   sessionStorage.setItem('practiceMode', true);
      // }
      sessionStorage.setItem('practiceMode', d.mode);
      setInvestmentAccountData(d);
      if (props.location.pathname.includes('/child/investments') && d.mode === 'Practice') {
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`child-module`));
        document.querySelectorAll(`body`).forEach(e => e.classList.add(`practice-mode`));
        document.querySelectorAll(`body`).forEach(e => e.style.backgroundColor = investmentBodyColor);
      }
      else {
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`child-module`));
        document.querySelectorAll(`body`).forEach(e => e.classList.remove(`practice-mode`));
        document.querySelectorAll(`body`).forEach(e => e.style.backgroundColor = '#ffffff');
      }
    }, err => {
    })
  }

  return (
    <>
      {user &&
        <>
          {showHeader ?
            <header className="header-chorecheck">
              <div className="navbar-container">
                <div className="logo">
                  <img src={props.state.theme.values.logo} className="cursor_pointer" alt="rego logo" onClick={() => window.location.reload()} />
                </div>
              </div>
            </header>
            : null}
          <div className="main-container">
            <div className={`dashboard-wrapper ${isSidebar ? 'sidebar' : 'nosidebar'}`}>
              <div className="toggleSidebar" onClick={toggleSidebar}><img src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/menuBar.svg`} alt="menubar" /></div>

              {isSidebar ?
                <>
                  <Sidebar {...props} showHeader={showHeader} chore={chore} />

                  <div className={`dashboard-right-article ${pageClass}`}>
                    <LoaderPost identifier={'0'} />

                    {isHeaderIcons === false ?
                      null
                      :
                      <>

                        {/* <h1 className={`dashboard-heading ${isNotification && helpIcon && languageIcon?'':'none'}`}>{t(headerTitle)} */}
                        <h1 className='dashboard-heading'>{t(headerTitle)}

                          <div className="heading-right-icon-list ml-auto">
                            {helpIcon ?
                              <span className="menuHelp">
                                <Link to={'/help'} rel="noreferrer">
                                  <InfoCircle {...props} color={props.state.theme.values.tertiaryGrayColor}/>
                                </Link>
                              </span>
                              : null}
                            {isNotification ?
                              <span className="dashboard-notification-wrapper">

                                <a onClick={(e) => { e.preventDefault() }} href="!#">
                                  <img alt="bell icon" src={`${BASE_URL.CDN}/tenants/${props.state.theme.values.tenantFullName}/web/public/assets/images/bell.svg`} onClick={() => { setnotificationModal(!notificationModal); }} />
                                  {/* <span className="fa fa-bell" onClick={() => { setnotificationModal(!notificationModal); }}></span> */}
                                  <span className="notification-unred-"></span>
                                </a>
                              </span>
                              : null}

                            {languageIcon ?
                              <span className="language-changer">
                                <ul className="navbar-nav ml-auto text-right">
                                  <li className="nav-item dropdown topLanguageChange">
                                    <a className="nav-link dropdown-toggle" href={`!#`} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      {window.sessionStorage.getItem("lang") ? window.sessionStorage.getItem("lang") : constants.FALLBACK_LANG}
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "en");
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000)
                                      }}>
                                        EN
                                      </a>
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "fr")
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000)
                                      }}>
                                        FR
                                      </a>
                                      <a href={`!#`} className="dropdown-item" onClick={e => {
                                        e.preventDefault()
                                        window.sessionStorage.setItem(constants.LOCALSTORAGE.lang, "no")
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000)
                                      }}>
                                        NO
                                      </a>
                                    </div>
                                  </li>
                                </ul>
                              </span>
                              : null}
                          </div>
                        </h1>
                      </>
                    }
                    <Suspense
                      fallback={<Loader />}
                    >

                      {renderRoutes(route.routes)}

                    </Suspense >
                  </div>

                </>
                : <>

                  {investmentSidebar ?
                    <>
                      <InvestmentSidebar {...props} />
                      <div className={`dashboard-right-article ${pageClass}`}>
                        <Suspense
                          fallback={<Loader />}
                        >

                          {renderRoutes(route.routes)}

                        </Suspense >
                      </div>
                    </>

                    :
                    <>
                      <Suspense
                        fallback={<Loader />}
                      >

                        {renderRoutes(route.routes)}

                      </Suspense >
                    </>
                  }
                </>
              }
            </div>

            <Bottom {...props} showHeader={showHeader} isMobileNav={isMobileNav} chore={chore} investmentSidebar={investmentSidebar} />
          </div>
        </>
      }
    </>
  );
};

export default AuthenticatedLayout;
