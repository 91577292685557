import $ from 'jquery';
import * as constants from './constants';
import classes from '../variables.module.scss';
import i18n from '../i18n';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { BASE_URL } from './url';
import TransactionDeposits from '../Layouts/Authenticated/Components/Sidebar/icons/settings/TransactionDeposits';
import TransactionTransfer from '../Layouts/Authenticated/Components/Sidebar/icons/settings/TransactionTransfer';
import TransactionBankFee from '../Layouts/Authenticated/Components/Sidebar/icons/settings/TransactionBankFee';

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const weekDaysName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const hideModal = (id) => {
    $(`#${id}`).modal('hide');
}

export const showModal = (id) => {
    $(`#${id}`).modal();
}

export const youtubeIframe = () => {
    $("#player").remove();
}

export const isJSON = (str) => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

export const showLoader = () => {
    //hideLoader();
    document.querySelectorAll('.loader-div').forEach(e => {
        e.classList.remove('hide');
    })
};

export const showLoaderPre = () => {
    //hideLoader();
    document.querySelectorAll('.loader-div-post').forEach(e => {
        e.classList.remove('hide');
    })
};


export const hideLoader = () => {
    document.querySelectorAll('.loader-div').forEach(e => {
        e.classList.add('hide');
    })

    document.querySelectorAll('.loader-div-post').forEach(e => {
        e.classList.add('hide');
    })
};

export const showLoaderGuest = () => {
    //hideLoader();
    document.querySelectorAll('.loader-div-post').forEach(e => {
        e.classList.remove('hide');
    })

    document.querySelectorAll('.loader').forEach(e => {
        e.classList.add('loader-post');
    })
};

export const validateEmail = (email) => {
    const re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return re.test(email);
}

export const disableEnterKeyPress = (event) => {
    if (event.key === 'Enter') {

        event.preventDefault();
    }
}


export const getVimdeoId = (url) => {
    var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    var match = url.match(regExp);

    if (match && match.length > 1) {
        return match[2];
    }
    return null;
}

export const yyymmdd = (date, minusDays) => {
    var delimitor = "-";
    var d = new Date(date);
    d.setDate(d.getDate() + minusDays);

    var dd = d.getDate();
    var mm = d.getMonth() + 1;
    var yyyy = d.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    d = `${yyyy}${delimitor}${mm}${delimitor}${dd}`;

    return d;
}

export const mmddyyyy = (date, delimitor = "-") => {

    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    today = `${mm}${delimitor}${dd}${delimitor}${yyyy}`;
    return today;
}


export const MM_ddyyyy = (date) => {

    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth();
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    today = `${monthNames[mm]} ${dd}, ${yyyy}`;

    return today;
}

export const MM_dd = (date) => {

    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    today = `${mm}/${dd}`;

    return today;
}


export const hhmm = (date, delimitor = ":") => {

    var d = new Date(date);
    var h = (d.getHours() < 10 ? '0' : '') + d.getHours();
    var m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

    d = `${h}${delimitor}${m}`;

    return d;
}

export const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export const yyyymmdd = (date, delimitor = "-") => {

    var today = new Date(date);
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }

    if (mm < 10) {
        mm = '0' + mm;
    }

    today = `${yyyy}${delimitor}${mm}${delimitor}${dd}`;

    return today;
}

export const dayName = (dateString) => {
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(dateString);
    var dayName = days[d.getDay()];
    return dayName;
}

export const dateFormat = (date, delimitor = " ") => {


    var newdate = new Date(date);

    var options = {
        // year: "numeric",
        month: "short",
        day: "numeric",
        hour: '2-digit',
        minute: '2-digit'
    };
    let newD = newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options);
    if (newD.charAt(4) == 0) {
        let splitDate = newD.split(' ');
        let getDate = splitDate[1].slice(1);
        splitDate[1] = getDate;
        let joinDate = splitDate.join(' ');
        newD = joinDate;
    }
    return newD;
}

export const dateOnlyFormat = (date, delimitor = " ") => {

    var newdate = new Date(date);

    var options = {
        year: "numeric",
        month: "short",
        day: "2-digit",
    };
    // return newdate.toLocaleDateString("en", options)
    let newD = newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options);
    if (newD.charAt(4) == 0) {
        let splitDate = newD.split(' ');
        let getDate = splitDate[1].slice(1);
        splitDate[1] = getDate;
        let joinDate = splitDate.join(' ');
        newD = joinDate;
    }

    return newD;
}

export const dayMonthYearFullFormat = (date, delimitor = " ") => {

    var newdate = new Date(date);

    var options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    return newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options)
}


export const dateFormatDayMonthYear = (date, delimitor = " ") => {
    var newdate = new Date(date);
    var options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: 'numeric',
        minute: '2-digit'
    };
    let newD = newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options);
    return newD;
}

export const getWeekDay = (date) => {
    var newdate = new Date(date);
    if (newdate.getMonth() + 1 == new Date().getMonth() + 1) {
        return weekDaysName[newdate.getDay()]
    } else {
        return dateFormatDayFullMonth(date)
    }
    // monthNames
}

export const dateFormatMonthYear = (date, delimitor = " ") => {

    var newdate = new Date(date);

    var options = {
        year: "numeric",
        month: "short",
    };

    return newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options)

}

export const getTruncateStr = (str, max = 15) => {
    return str.length > max ? str.substring(0, max) + "..." : str
}

export const fontSizesEnum = () => {
    return [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 56]
}

export const brandingColorViaProps = (props) => {
    const colorArray = [
        {
            key: 'primaryColor',
            value: props.state.theme.values.primaryColor
        },
        {
            key: 'tertiaryColor',
            value: props.state.theme.values.tertiaryColor
        },
        {
            key: 'tertiaryGrayColor',
            value: props.state.theme.values.tertiaryGrayColor
        },
        {
            key: 'tertiaryWardColor',
            value: props.state.theme.values.tertiaryWardColor
        },
        {
            key: 'primaryGrayColor',
            value: props.state.theme.values.primaryGrayColor
        },
        {
            key: 'primaryWardColor',
            value: props.state.theme.values.primaryWardColor
        },
        {
            key: 'secondaryColor',
            value: props.state.theme.values.secondaryColor
        },
        {
            key: 'secondaryGrayColor',
            value: props.state.theme.values.secondaryGrayColor
        },
        {
            key: 'secondaryWardColor',
            value: props.state.theme.values.secondaryWardColor
        },
        {
            key: 'gray5',
            value: props.state.theme.values.gray5
        }
    ]

    return colorArray;
}

export const fontSizeChange = (fontSize) => {
    if (fontSize === "0") {
        document.body.style.zoom = 1.0;
    } else if (fontSize === "1") {
        document.body.style.zoom = 1.2;
    } else if (fontSize === "-1") {
        document.body.style.zoom = 0.8;
    } else {
        document.body.style.zoom = 1.0;
    }
}

export const colorChange = (props, contrast, dispatch) => {
    const initalThemeValues = JSON.parse(JSON.stringify(props.state.theme.initalThemeValues));
    const colors = brandingColorViaProps(props);
    colors.forEach(element => {
        if (contrast == "true") {
            const contrastColor = formatContrastColorsDark(element.value);
            initalThemeValues[element.key] = contrastColor;
            document.documentElement.style.setProperty(`--${element.key}`, contrastColor);
        } else {
            document.documentElement.style.setProperty(`--${element.key}`, props.state.theme.initalThemeValues[element.key]);
        }
    });

    if (contrast != "true") {
        dispatch({
            type: 'THEME_SET_TRIGGER', payload: {
                initalThemeValues: props.state.theme.initalThemeValues,
                values: props.state.theme.initalThemeValues
            }
        })
    } else {
        dispatch({
            type: 'THEME_SET_TRIGGER', payload: {
                initalThemeValues: props.state.theme.initalThemeValues,
                values: initalThemeValues
            }
        })
    }
}

export const getDisputeStatus = (label) => {
    if (label.includes('In Dispute')) {
        return 'InDispute';
    }
    if (label.includes('Unsuccessful')) {
        return 'DisputeUnsuccessful'
    }
    if (label.includes('Successful')) {
        return 'DisputeSuccessful';
    }
}

export const disputeErrorClass = (label) => {
    if (label.includes('In Dispute')) {
        return 'childThemeColor';
    }
    if (label.includes('Unsuccessful')) {
        return 'errorColor'
    }
    if (label.includes('Successful')) {
        return 'tertiaryColor';
    }
}

export const formatContrastColorsDark = (value) => {
    let convertedColor = checkAndConvertFormHexToHSL(value);
    const contrastReferenceColor = 'hsl(180,5%,88%)';
    const contrastColor = formatContrastColors(convertedColor, contrastReferenceColor);
    return contrastColor;
}

export const checkAndConvertFormHexToHSL = (color) => {
    if (!color) { return }
    if (!color.startsWith('hsl')) {
        let r, g, b;
        if (color.length === 4) {
            r = '0x' + color[1] + color[1];
            g = '0x' + color[2] + color[2];
            b = '0x' + color[3] + color[3];
        } else {
            r = '0x' + color[1] + color[2];
            g = '0x' + color[3] + color[4];
            b = '0x' + color[5] + color[6];
        }
        r /= 255;
        g /= 255;
        b /= 255;
        let hue, saturation, lightness;
        const min = Math.min(r, g, b);
        const max = Math.max(r, g, b);
        if (max === min) {
            hue = 0;
        } else if (max === r) {
            hue = 60 * ((g - b) / (max - min));
        } else if (max === g) {
            hue = 60 * (2 + (b - r) / (max - min));
        } else if (max === b) {
            hue = 60 * (4 + (r - g) / (max - min));
        }
        if (hue < 0) {
            hue += 360;
        }
        lightness = (min + max) / 2;
        if (max === 0 || min === 1) {
            saturation = 0;
        } else {
            saturation = (max - lightness) / Math.min(lightness, 1 - lightness);
        }
        saturation *= 100;
        lightness *= 100;
        return (
            'hsl(' +
            Math.round(hue) +
            ', ' +
            Math.round(saturation) +
            '%, ' +
            Math.round(lightness) +
            '%)'
        );
    }
    return color;
};

export const formatContrastColors = (textColor, backgroundColor) => {
    const targetContrastRatio = 4.5;

    function hslStringToHslArray(hslString) {
        const regex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/;
        const match = hslString.match(regex);

        if (!match) {
            throw new Error('Invalid HSL string format');
        }

        return [parseInt(match[1]), parseInt(match[2]), parseInt(match[3])];
    }

    function hslArrayToHslString(hslArray) {
        return `hsl(${hslArray[0]}, ${hslArray[1]}%, ${hslArray[2]}%)`;
    }

    function hslToRgb(h, s, l) {
        h /= 360;
        s /= 100;
        l /= 100;
        let r, g, b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;

            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    const gammaCorrect = (value) => {
        if (value <= 0.03928) {
            return value / 12.92;
        } else {
            return Math.pow((value + 0.055) / 1.055, 2.4);
        }
    };

    const luminance = (color) => {
        const rgb = hslToRgb(color[0], color[1], color[2]);
        const correctedRgb = rgb.map((component) => gammaCorrect(component / 255));
        return (
            0.2126 * correctedRgb[0] +
            0.7152 * correctedRgb[1] +
            0.0722 * correctedRgb[2]
        );
    };

    function calculateContrastRatio(color1, color2) {
        const luminance1 = luminance(hslStringToHslArray(color1));
        const luminance2 = luminance(hslStringToHslArray(color2));

        const brighter = Math.max(luminance1, luminance2);
        const darker = Math.min(luminance1, luminance2);

        return (brighter + 0.05) / (darker + 0.05);
    }

    const contrastRatio = calculateContrastRatio(textColor, backgroundColor);

    function calculateTextColor(color) {
        const backgroundColorLuminance = luminance(
            hslStringToHslArray(backgroundColor)
        );
        const textColorRequiredLuminance =
            (backgroundColorLuminance + 0.05 - targetContrastRatio * 0.05) /
            targetContrastRatio;
        let textColorCurrentLuminance = luminance(hslStringToHslArray(color));
        const colorToArray = hslStringToHslArray(color);
        while (textColorCurrentLuminance > textColorRequiredLuminance) {
            colorToArray[2] -= 1;
            color = hslArrayToHslString(colorToArray);
            textColorCurrentLuminance = luminance(hslStringToHslArray(color));
        }
        return color;
    }

    if (contrastRatio >= targetContrastRatio) {
        return textColor;
    } else {
        return calculateTextColor(textColor);
    }
}

export const dateFormatDayMonth = (date) => {

    var newdate = new Date(date);

    var options = {
        month: "short",
        day: "2-digit",
    };

    // return newdate.toLocaleDateString("en", options)
    let dt = newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options);

    if (dt.charAt(4) == 0) {
        let splitDate = dt.split(' ');
        let getDate = splitDate[1].slice(1);
        splitDate[1] = getDate;
        let joinDate = splitDate.join(' ');
        dt = joinDate;
    }
    return dt;

}

export const dateFormatDayFullMonth = (date) => {

    var newdate = new Date(date);

    var options = {
        month: "long",
        day: "numeric",
    };

    // return newdate.toLocaleDateString("en", options)
    let dt = newdate.toLocaleDateString(window.sessionStorage.getItem('lang') || "en", options);

    if (dt.charAt(4) == 0) {
        let splitDate = dt.split(' ');
        let getDate = splitDate[1].slice(1);
        splitDate[1] = getDate;
        let joinDate = splitDate.join(' ');
        dt = joinDate;
    }
    return dt;

}

export const getMerchantLogo = (transactionData, props) => {
    if (
        transactionData.description.includes('transfer') ||
        transactionData.topLevelCategory == "Transfer" ||
        transactionData.topLevelCategory == "Credit Card Payment" ||
        transactionData.topLevelCategory == "Transfer for Cash Spending" ||
        transactionData.topLevelCategory == "Mortgage Payment"
    ) {
        return <TransactionTransfer {...props} color={props.state.theme.values.primaryColor} />
    } else if (
        transactionData.isIncome == true || transactionData.type == 'DEBIT'
    ) {
        return <TransactionDeposits {...props} color={props.state.theme.values.primaryColor} />
    } else {
        return <TransactionBankFee {...props} color={props.state.theme.values.primaryColor} />
    }
}

export const splitMetadataLogo = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.LogoUrl ? splitIndexOne.LogoUrl : ``;
}

export const splitMetadataMerchantLogo = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    const MerchantLogo = splitIndexOne.MerchantLogo ? splitIndexOne.MerchantLogo : ``;
    if (MerchantLogo) {
        return MerchantLogo;
    }
    return splitIndexOne.LogoUrl ? splitIndexOne.LogoUrl : ``;
}

export const splitMetadataBank = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.BankName ? splitIndexOne.BankName : ``;
}

export const splitMetadataData = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.fromName ? splitIndexOne.fromName : ``;
}

export const splitMetadataGoal = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.Name ? splitIndexOne.Name : ``;
}

export const splitMetadataGoalToAccountId = (data, accountsList) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    let accountData;
    accountsList.forEach((e) => {
        if (e.child.accounts.filter(e => e.accountId == splitIndexOne.ToAccountId)[0]) {
            accountData = e.child.accounts.filter(e => e.accountId == splitIndexOne.ToAccountId)[0]
        }
    })
    return accountData?.name || '';
}

export const splitScheduledTransferRecurrence = (data) => {
    let occurance_name;
    let day_name;
    let day;
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    else if (splitIndexOne.days_of_month && splitIndexOne.days_of_month.length > 0) {
        day = 'On the ' + getDaysInCurrentMonth(splitIndexOne.days_of_month[0]);
    }
    else if (splitIndexOne.days_of_week != null && splitIndexOne.occurrences != null) {
        occurance_name = splitIndexOne.occurrences[0] == '1' ? 'First' : splitIndexOne.occurrences[0] == '2' ? 'Second' : splitIndexOne.occurrences[0] == '3' ? 'Third' : splitIndexOne.occurrences[0] == '4' ? 'Fourth' : 'Fifth';
        day_name = splitIndexOne.days_of_week[0] == '0' ? 'Sunday' : splitIndexOne.days_of_week[0] == '1' ? 'Monday' : splitIndexOne.days_of_week[0] == '2' ? 'Tuesday' : splitIndexOne.days_of_week[0] == '3' ? 'Wednesday' :
            splitIndexOne.days_of_week[0] == '4' ? 'Thursday' : splitIndexOne.days_of_week[0] == '5' ? 'Friday' : splitIndexOne.days_of_week[0] == '6' ? 'Saturday' : '';
        day = occurance_name + ' ' + day_name
    }
    else if (splitIndexOne.days_of_week != null && splitIndexOne.occurrences == null) {
        // const WEEK = [t('weeks_txt_medium.sunday'), t('weeks_txt_medium.monday'), t('weeks_txt_medium.tuesday'), t('weeks_txt_medium.wednesday'), t('weeks_txt_medium.thursday'), t('weeks_txt_medium.friday'), t('weeks_txt_medium.saturday'), t('weeks_txt_medium.sunday')];
        const WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        let localWeek = [];
        const sortedWeekDays = splitIndexOne.days_of_week.sort();
        sortedWeekDays.forEach((e, idx) => {
            if (e != '7') {
                localWeek.push(`${WEEK[e]}`);
            }
        })
        const joinedWeek = localWeek.join(", ");
        day = joinedWeek
    }
    else if (splitIndexOne.days_of_week == null && splitIndexOne.occurrences == null && splitIndexOne.dates != null) {
        const getDay = new Date(splitIndexOne.dates).getDay();
        day = getDay == '0' ? 'Sunday' : getDay == '1' ? 'Monday' : getDay == '2' ? 'Tuesday' : getDay == '3' ? 'Wednesday' : getDay == '4' ? 'Thursday' : getDay == '5' ? 'Friday' : getDay == '6' ? 'Saturday' : '';
    }

    return day;
}

export const scheduledTransferLabel = (data) => {
    let label;
    if (data) {
        label = `Scheduled Transfer To`
    }
    else {
        label = `Transfer To`
    }
    return label;
}

export const splitMetadataDataChore = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.Name;
}

export const splitMetadataStockQty = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.Quantity;
}

export const splitMetadataTicker = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.Ticker;
}

export const splitMetadataInvestmentAmount = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return `${splitIndexOne.Amount ? splitIndexOne.Amount.toFixed(2) : 0.00}`;
}

export const splitMetadataGoalName = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return '';
    }
    if (splitIndexOne.Name == "null") {
        return "";
    }

    if (!splitIndexOne.Name) {
        return "";
    }

    return ": " + splitIndexOne.Name;
}

export const splitMetadataDataCharityName = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.Name;
}

export const splitMetadataDataMerchantName = (data) => {
    const splitIndexOne = JSON.parse(data.data);
    if (!splitIndexOne) {
        return null;
    }
    const mName = splitIndexOne.merchant?.name;
    if (mName) {
        return mName;
    }
    const splitIndexOneMetadata = JSON.parse(data.metadata);
    if (!splitIndexOneMetadata) {
        return null;
    }
    return splitIndexOneMetadata?.Name;
}

export const splitMetadataDataLegal = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return splitIndexOne.merchant?.name;
}


export const splitDataNextSchedule = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return dateFormatDayMonth(splitIndexOne.NextScheduledDate);
}

export const splitDataFreq = (data) => {
    const splitIndexOne = JSON.parse(data);
    if (!splitIndexOne) {
        return null;
    }
    return dateFormatDayMonth(splitIndexOne.Frequency);
}


export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const last4Digit = (accountNumber) => {
    return accountNumber.substr(-4);
}

export const decimalPt = (data, toFixed = 2) => {
    data = !data ? "0" : data.toString();

    if (data.indexOf(".") === -1) {
        return numberWithCommas(parseFloat(data).toFixed(toFixed));
    }

    return numberWithCommas(parseFloat(data).toFixed(toFixed));
}

export const decimalPtWithSymbolElseDash = (data) => {

    const val = decimalPt(data, 0);
    if (val <= 0) {
        return `-$${(val).replace("-", "")}`;
    }

    return `$${val}`;
}

export const decimalPtWithSymbol = (data) => {

    const val = decimalPt(data);
    if (val < 0) {
        return `-$${(val).replace("-", "")}`;
    }

    return `$${val}`;
}

export const decimalPtWithSymbolInNegative = (data) => {

    const val = decimalPt(data);
    if (val < 0) {
        return `-$${(val).replace("-", "")}`;
    }
    return `$${val}`;
}

export const convertToBlankAmount = (data) => {
    return ``;
}

export const removeSpecialChar = (str) => {
    return str;
    //return str.replace(/[^a-zA-Z]/g, " ").trim()
}

export const getDaysInCurrentMonth = (date) => {
    const data = [
        { day: '1st', value: 1 },
        { day: '2nd', value: 2 },
        { day: '3rd', value: 3 },
        { day: '4th', value: 4 },
        { day: '5th', value: 5 },
        { day: '6th', value: 6 },
        { day: '7th', value: 7 },
        { day: '8th', value: 8 },
        { day: '9th', value: 9 },
        { day: '10th', value: 10 },
        { day: '11th', value: 11 },
        { day: '12th', value: 12 },
        { day: '13th', value: 13 },
        { day: '14th', value: 14 },
        { day: '15th', value: 15 },
        { day: '16th', value: 16 },
        { day: '17th', value: 17 },
        { day: '18th', value: 18 },
        { day: '19th', value: 19 },
        { day: '20th', value: 20 },
        { day: '21st', value: 21 },
        { day: '22nd', value: 22 },
        { day: '23rd', value: 23 },
        { day: '24th', value: 24 },
        { day: '25th', value: 25 },
        { day: '26th', value: 26 },
        { day: '27th', value: 27 },
        { day: '28th', value: 28 },
        { day: '29th', value: 29 },
        { day: '30th', value: 30 },
        { day: '31st', value: 31 },
    ];
    if (date) {
        return data.find((e) => e.value == Number(date)).day;
    }
    return data;
}

export const capitalizeFirstLetter = (string) => {
    if (!string) { return "" }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const isLoginModule = () => {
    if (window.location.port === '3001' || window.location.port === '3000'
        || window.location.host === 'signin.mazoola.co' || window.location.host === 'app.mazoola.co') {
        return true;
    }

    return false;
}

export const isSignupModule = () => {
    if (window.location.port === '3002' || window.location.port === '3000'
        || window.location.host === 'register.mazoola.co' || window.location.host === 'app.mazoola.co') {
        return true;
    }

    return false;
}

export const phoneNumberAutoFormat = (phoneNumber) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, "");

    // const phoneNumberLength = number.length;
    // if (phoneNumberLength < 4) return number;
    // if (phoneNumberLength < 7) {
    //     return `(${phoneNumber.slice(0, 3)}) ${number.slice(3)}`;
    // }
    // return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;


    if (number.length < 4) return number;
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "($1) $2");
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "($1) $2-$3");
    return number.replace(/(\d{3})(\d{4})(\d{4})/, "($1) $2-$3");
};

export const SSNAutoFormat = (ssn) => {
    const number = ssn.trim().replace(/[^0-9]/g, "");
    if (number.length < 4) return number;
    if (number.length < 6) return number.slice(0, 3) + "-" + number.slice(3, 5);
    if (number.length < 10) return number.slice(0, 3) + "-" + number.slice(3, 5) + "-" + number.slice(5);
};

export function timeSince(date) {

    var seconds = Math.floor((new Date() - new Date(date)) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " mo";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " h";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " m";
    }
    return Math.floor(seconds) + " s";
}

export const getChartData = (res, changePercent) => {
    let labels = [];
    let dt = [];
    res.forEach(e => {
        labels.push(hhmm(e.timeUtc));
        dt.push(e.price.toFixed(2));
    })

    return {
        labels: labels,
        datasets: [
            {
                label: 'Price',
                data: dt,
                fill: "start",
                lineTension: 0,
                backgroundColor: (changePercent < 0) ? "rgba(241,69,69,0.2)" : "rgba(75,192,192,0.2)",
                borderColor: (changePercent < 0) ? classes.dangerColor : classes.themeGreen1
            }
        ]
    }
}


export const getChartDataTicker = (res, changePercent) => {
    let labels = [];
    let dt = [];
    res.forEach(e => {
        labels.push(hhmm(new Date().toISOString().split('T')[0]));
        dt.push((e) ? e.toFixed(2) : null);
    })
    return {
        labels: labels,
        datasets: [
            {
                label: 'Price',
                data: dt,
                //fill: "start",
                lineTension: 0,
                //backgroundColor: (changePercent < 0) ? "rgba(241,69,69,0.2)" : "rgba(75,192,192,0.2)",
                borderColor: (changePercent < 0) ? classes.dangerColor : classes.themeGreen1
            }
        ]
    }
}

export const getChartDataTickerPortfolioDetail = (res, tab, module) => {
    let labels = [];
    let dt = [];
    if (res && res.length > 0) {
        res.forEach(e => {
            if (tab === 1) {
                labels.push(hhmm(e.timeUtc));
            } else if (tab === 2) {
                labels = ["Mon", "Tue", "Wed", "Thu", "Fri"];
            } else if (tab === 3) {
                labels = ['Week 1', 'Week 2', 'Week 3', 'Week 4'];
            } else if (tab === 4) {
                labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            }
            // labels.push(hhmm(new Date().toISOString().split('T')[0]));
            dt.push((e) ? e.price.toFixed(2) : null);
        })

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Price',
                    data: dt,
                    //fill: "start",
                    lineTension: 0,
                    //backgroundColor: (changePercent < 0) ? "rgba(241,69,69,0.2)" : "rgba(75,192,192,0.2)",
                    borderColor: module == 'child' ? classes.childtheme : classes.themeGreen1
                }
            ]
        }
    }
}


export const getChartDataTickerSpending90 = (res, changePercent, type) => {



    let labels = [];
    let dt = [];

    const d = new Date();
    d.setDate(d.getDate() - 90);

    for (var i = 0; i <= 90; i++) {
        const innerDt = d;
        innerDt.setDate(innerDt.getDate() + i);
        labels.push(hhmm(innerDt.toISOString().split('T')[0]));

        const e = res[i];
        dt.push(e);
    }
    const _return = {
        labels: labels,
        datasets: [
            {
                label: 'Price',
                data: dt,
                //fill: "start",
                lineTension: 0,
                //backgroundColor: (changePercent < 0) ? "rgba(241,69,69,0.2)" : "rgba(75,192,192,0.2)",
                borderColor: type
            }
        ]
    };
    //console.log(_return);
    return _return;
}


export const windowOpen = (url) => {
    window.open(url, '_blank', 'location=yes,top=40,left=300,height=670,width=820,scrollbars=yes,status=no,toolbar=no, location=no,menubar=no,copyhistory=no,titlebar=no');
}

export const changeLang = async (tenant) => {
    showLoaderGuest();

    let _tenant = tenant;
    let defaultTenant = "default";
    const useDefaultTranslations = ["MazoolaPlus", "Mazoola", "Q2", "Rego", "TreasuryPrime", "Banno", "DemoBank"];
    tenant = (useDefaultTranslations.find(e => e === tenant)) ? defaultTenant : tenant;
    const user_lang = window.sessionStorage.getItem(constants.LOCALSTORAGE.lang) || "en";
    console.log(tenant);
    if (tenant !== "default") {
        const college = (await fetch(`${BASE_URL.CDN}/tenants/${tenant}/assets/translations/${user_lang}.json`))
        console.log(college);
        const json = await college.json();
        if (json) {
            //i18n.removeResourceBundle("en","translation");
            i18n.addResourceBundle(user_lang, "translation", json, true, true);
            i18n.changeLanguage(user_lang)
        }
    }

    try {
        const overRide = (await fetch(`${BASE_URL.CDN}/tenants/${_tenant}/assets/translations/${user_lang}-override.json`))
        if (overRide.ok) {
            const overRideJson = await overRide.json();
            console.log(overRideJson);
            if (overRideJson) {
                //i18n.removeResourceBundle("en","translation");
                i18n.addResourceBundle(user_lang, "translation", overRideJson, true, true);
                i18n.changeLanguage(user_lang)
            }
        }

    } catch (err) {
        console.warn(err);
    }

    hideLoader();
}

export const getTenantName = (appIdToTenantMap) => {
    let defaultTenant = "Mazoola";
    const host = (new URL(window.location.href)).hostname;

    if (appIdToTenantMap) {
        const tenant = appIdToTenantMap[host];
        if (tenant) {

            changeLang(tenant);

            sessionStorage.setItem("tenant", tenant);
            localStorage.setItem("tenant", tenant);
            return tenant;
        }
    }

    // let tenantsName = constants.ENTITY_BRAND.MAZOOLA;
    // if (window.location.host === 'regostage.regopayments.com') {
    //     tenantsName = constants.ENTITY_BRAND.REGO;
    // } else if (window.location.host === 'mazoolastage.regopayments.com') {
    //     tenantsName = constants.ENTITY_BRAND.MAZOOLA;
    // }
    sessionStorage.setItem("tenant", defaultTenant);
    localStorage.setItem("tenant", defaultTenant);
    return defaultTenant;
}

export const isCollege = () => {
    if (sessionStorage.getItem("tenant") == "College") {
        return true;
    }

    return false;
}

export const isFeatureDisabled = (props, permissionName) => {
    if (props.state.feature.featureWardtoggle && props.state.feature.featureWardtoggle.length) {
        return props.state.feature.featureWardtoggle.findIndex((e) => e === permissionName) != -1 ? false : true;
    }
    return true;
}

export const isPermission = (props, permissionName) => {
    //console.log('props.state.feature.featuretoggle',props.state.feature.featuretoggle)
    //console.log('props.state.feature.featuretoggleFlat',props.state.feature.featuretoggleFlat)
    let permission = true;
    permissionName = permissionName ? permissionName.toLowerCase() : "";

    if (props.state && props.state.feature && props.state.feature.featuretoggle && props.state.feature.featuretoggle.length > 0) {
        const ft = props.state.feature.featuretoggle;
        ft.forEach(e => {
            if (e.name.name ? e.name.name.toLowerCase() === permissionName : e.name.toLowerCase() === permissionName) {
                return true;
            }

            if (e.disableFeatures) {
                const isDisable = e.disableFeatures.find(f => f.toLowerCase() === permissionName);
                if (isDisable) {
                    return false;
                }
            }
        })
    }

    if (props.state && props.state.feature && props.state.feature.featuretoggleFlat) {
        const ft = props.state.feature.featuretoggleFlat.find(f => f.name.name ? f.name.name.toLowerCase() === permissionName : f.name.toLowerCase() === permissionName && !f.isActive);
        if (ft) {
            return false;
        }
    }

    return permission;
}

export const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2]);
}

export const getSectorTranlationKey = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    let key = '';
    if (name == "TV Entertainment") {
        key = 'tv'
    } else if (name == 'Automobile') {
        key = 'automobile'
    } else if (name == 'Industrial') {
        key = 'industrial'
    } else if (name == 'Finance') {
        key = 'finance'
    } else if (name == 'Travel Hospitality') {
        key = 'travel'
    } else if (name == 'Retail') {
        key = 'retail'
    } else if (name == 'Real Estate') {
        key = 'realEstate'
    } else if (name == 'Sport Gear') {
        key = 'sport'
    } else if (name == 'Technology') {
        key = 'tech'
    } else if (name == 'Communication') {
        key = 'comm'
    } else if (name == 'Beauty & Fashion') {
        key = 'beauty_fashion'
    } else if (name == 'Food') {
        key = 'food'
    } else if (name == 'Health Wellness') {
        key = 'health'
    } else if (name == 'Miscellaneous') {
        key = 'miscellaneous'
    }
    return key;
}

export const initFirebase = async (setfcmToken) => {

    if (Notification.permission == "denied") {
        setfcmToken(null);
        return false;
    }

    const firebaseConfig = {
        apiKey: "AIzaSyB06LkrZ-WDGCif3yZTSKdOtJrwfEAFrfA",
        authDomain: "saurabhtest-c5346.firebaseapp.com",
        projectId: "saurabhtest-c5346",
        storageBucket: "saurabhtest-c5346.appspot.com",
        messagingSenderId: "471032993348",
        appId: "1:471032993348:web:52e2b30ea673ac66770e11",
        measurementId: "G-K2T8KTN98Y"
    };

    const firebaseApp = initializeApp(firebaseConfig);
    if (!('serviceWorker' in navigator)) {
        console.warn("Service Worker is not supported in this browser.");
        return;
    }

    try {
        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;
        console.log("Service Worker is ready:", registration);

        // Initialize Firebase Cloud Messaging
        const messaging = getMessaging(firebaseApp);
        //console.log(messaging);
        return _getToken(setfcmToken, messaging, 'BN2_bGoy82jtHICKAkiwZ6BMTm1TrkYVvT0yR6UqpzYsf4VIG7VqBmYhc_NRA_5tHcBKO8sNTLNo2qVO51zMOcQ');
    } catch (error) {
        console.error("Error initializing Firebase Messaging:", error);
    }
    console.warn("Firebase err")
    return null;
}

export const _getToken = async (setfcmToken, messaging, vapidKey) => {
    try {
        const fcmToken = await getToken(messaging, { vapidKey: vapidKey });
        if (!fcmToken) {
            console.warn("Enable Notification Service");
            return null;
        }
        console.log({ token: fcmToken });
        setfcmToken({ token: fcmToken });
        return { token: fcmToken };
    } catch (err) {
        console.warn(err);
        setfcmToken(null);
        return false;
    }
}

export const getLocation = async (setloc) => {
    try {
        const getCurrentPosition = () => {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    position => resolve(position),
                    error => reject(error)
                )
            })
        }

        const position = await getCurrentPosition();
        if (!position) {
            console.warn("Enable Location Service");
            return null;
        }
        const { latitude, longitude } = position.coords;
        //console.log({latitude,longitude});
        setloc({ latitude, longitude })
        return { latitude, longitude };
    } catch (err) {
        console.warn(err);
        setloc(null)
        return null;
    }
}

export const getBatteryLevel = async (setbattery) => {
    if (!window.navigator.getBattery) {
        return { battery_level: '-1.00' }
    }
    const battery = await window.navigator.getBattery();
    if (!battery) {
        console.warn("Battery Level Error");
        setbattery({ battery_level: 0 })
        return { battery_level: 0 }
    }
    //console.log({battery_level:battery.level*100});
    setbattery({ battery_level: battery.level * 100 })
    return { battery_level: (battery.level * 100).toFixed(2) };
}

export const getDeviceName = () => {
    let device = "Unknown";
    const ua = {
        "Generic Linux": /Linux/i,
        "Android": /Android/i,
        "BlackBerry": /BlackBerry/i,
        "Bluebird": /EF500/i,
        "Chrome OS": /CrOS/i,
        "Datalogic": /DL-AXIS/i,
        "Honeywell": /CT50/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "iPod": /iPod/i,
        "macOS": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
        "Zebra": /TC70|TC55/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    //console.log({device_type:device})
    return { device_type: device };
}

export const getDeviceInfo = (setdevice) => {
    var module = {
        options: [],
        header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
        dataos: [
            { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
            { name: 'Windows', value: 'Win', version: 'NT' },
            { name: 'iPhone', value: 'iPhone', version: 'OS' },
            { name: 'iPad', value: 'iPad', version: 'OS' },
            { name: 'Kindle', value: 'Silk', version: 'Silk' },
            { name: 'Android', value: 'Android', version: 'Android' },
            { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
            { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
            { name: 'Macintosh', value: 'Mac', version: 'OS X' },
            { name: 'Linux', value: 'Linux', version: 'rv' },
            { name: 'Palm', value: 'Palm', version: 'PalmOS' }
        ],
        databrowser: [
            { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
            { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
            { name: 'Safari', value: 'Safari', version: 'Version' },
            { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
            { name: 'Opera', value: 'Opera', version: 'Opera' },
            { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
            { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
        ],
        init: function () {
            var agent = this.header.join(' '),
                os = this.matchItem(agent, this.dataos),
                browser = this.matchItem(agent, this.databrowser);

            return { os: os, browser: browser };
        },
        matchItem: function (string, data) {
            var i = 0,
                j = 0,
                html = '',
                regex,
                regexv,
                match,
                matches,
                version;

            for (i = 0; i < data.length; i += 1) {
                regex = new RegExp(data[i].value, 'i');
                match = regex.test(string);
                if (match) {
                    regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                    matches = string.match(regexv);
                    version = '';
                    if (matches) { if (matches[1]) { matches = matches[1]; } }
                    if (matches) {
                        matches = matches.split(/[._]+/);
                        for (j = 0; j < matches.length; j += 1) {
                            if (j === 0) {
                                version += matches[j] + '.';
                            } else {
                                version += matches[j];
                            }
                        }
                    } else {
                        version = '0';
                    }
                    return {
                        name: data[i].name,
                        version: parseFloat(version)
                    };
                }
            }
            return { name: 'unknown', version: 0 };
        }
    };
    var fullVersion = '' + parseFloat(navigator.appVersion);
    var e = module.init();
    //console.log(e);
    setdevice({ device_type: navigator.appName, device_model: navigator.appName, os_version: fullVersion, browserMetadata: navigator.userAgent })
    return { device_type: navigator.appName, device_model: navigator.appName, os_version: fullVersion, browserMetadata: navigator.userAgent }
}

//DETECTING BROWSER
if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
    $('body').addClass('opera');
}
else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    $('body').addClass('chrome');
}
else if (navigator.userAgent.indexOf("Safari") !== -1) {
    $('body').addClass('safari');
}
else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    $('body').addClass('firefox');
}
else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
    $('body').addClass('ie');
}
//DETECTING BROWSER

//PREVENTING BROWSER BACK ON MY FAMILY PAGE
window.addEventListener('popstate', function (event) {
    if (window.location.pathname == '/login') {
        window.history.pushState(null, null, `/${sessionStorage.getItem('loginType')}/dashboard`);
    }
}, false);