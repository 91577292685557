import React from "react";

const Family = (props) => {
  return (
    <svg
      id="myFamilyIcon"
      width="33"
      height="29"
      viewBox="0 0 33 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="stroke"
        d="M28.7051 12.5291C28.562 12.4879 28.4299 12.4585 28.2923 12.4349C27.3676 6.28201 22.381 1.58789 16.3706 1.58789C10.3492 1.58789 5.36261 6.28789 4.44344 12.4467C4.32786 12.4702 4.20677 12.4938 4.08568 12.5291C1.32817 13.2761 2.17028 15.0349 2.6051 17.0055C3.00689 18.8173 3.64536 19.9644 5.33509 19.7526C7.21746 24.2644 11.45 27.4114 16.3706 27.4114C21.2967 27.4114 25.5293 24.2644 27.4062 19.7467C29.1124 19.9879 29.7784 18.8349 30.1802 17.0055C30.6205 15.0349 31.4571 13.2761 28.7051 12.5291Z"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        id="lip"
        d="M12.6895 20.5C12.6895 20.5 13.606 22 16.3923 22C19.1785 22 20.0951 20.5 20.0951 20.5"
        stroke={props.color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        id="childHead"
        d="M28.0221 11.0889C23.1951 13.5948 19.0726 6.78895 21.4724 2.81836C21.4724 2.81836 24.2959 4.39483 25.5343 6.08895C27.2406 8.43601 28.0221 11.0889 28.0221 11.0889Z"
        fill={props.color}
      />
      <path
        id="childHead"
        d="M21.9177 3.02979C18.9731 1.58861 15.7422 0.976848 12.4949 2.28861C9.24751 3.60038 7.7174 4.91214 5.36719 9.16508C9.79241 11.2416 18.7034 10.4945 21.9177 3.02979Z"
        fill={props.color}
      />
      <ellipse
        id="eye"
        cx="11.7645"
        cy="15.5"
        rx="1.38856"
        ry="1.5"
        fill={props.color}
      />
      <ellipse
        id="eye"
        cx="21.0204"
        cy="15.5"
        rx="1.38856"
        ry="1.5"
        fill={props.color}
      />
    </svg>
  );
};

export default Family;
