import React from "react";

const QrCode = (props) => {

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.79 34.79"
    >
      <path
        d="M12.97,34.62h-6.19C2.73,34.62,0,31.61,0,27.14v-5.2c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v5.2c0,1.23.33,5.23,4.53,5.23h6.19c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M1.3,14.1c-.62,0-1.12-.5-1.12-1.12v-6.19C.17,2.72,3.18,0,7.65,0h5.19c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12h-5.19c-1.23,0-5.23.33-5.23,4.53v6.19c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M33.67,13.97c-.62,0-1.12-.5-1.12-1.12v-5.19c0-1.23-.33-5.23-4.53-5.23h-6.19c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h6.19c4.06,0,6.78,3,6.78,7.48v5.19c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M27.14,34.79h-5.2c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h5.2c1.23,0,5.23-.33,5.23-4.53v-6.19c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v6.19c0,4.06-3,6.78-7.48,6.78Z"
        fill={props.color}
      />
      <polygon
        points="28.29 18.9 17.14 18.9 17.14 15.9 25.29 15.9 25.29 12.88 17.39 12.88 17.39 6.39 20.39 6.39 20.39 9.88 28.29 9.88 28.29 18.9"
        fill={props.color}
      />
      <rect x="20.32" y="15.9" width="3" height="12.24" fill={props.color} />
      <rect x="6.5" y="25.13" width="21.79" height="3" fill={props.color} />
      <rect x="12.02" y="15.9" width="3" height="12.24" fill={props.color} />
      <polygon
        points="15.02 22.52 6.5 22.52 6.5 15.9 9.5 15.9 9.5 19.52 15.02 19.52 15.02 22.52"
        fill={props.color}
      />
      <polygon
        points="9.5 14.47 6.5 14.47 6.5 6.39 15.02 6.39 15.02 9.39 9.5 9.39 9.5 14.47"
        fill={props.color}
      />
      <rect x="11.35" y="11.14" width="3.68" height="3" fill={props.color} />
      <rect x="24.93" y="20.72" width="3.36" height="3" fill={props.color} />
    </svg>
  );
};

export default QrCode;
