import React from "react";

const Timeline = (props) => {
  return (
    <svg
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="13.2998"
        cy="12.5"
        r="11.5"
        stroke={props.color}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12.7998 7L12.7998 14L16.2998 17.5"
        stroke={props.color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Timeline;
