import React from "react";

const Wallet = (props) => {
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.32 28.72"
    >
      <path
        d="M4.83,9.66C2.17,9.66,0,7.49,0,4.83S2.17,0,4.83,0c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12c-1.42,0-2.58,1.16-2.58,2.58s1.16,2.58,2.58,2.58c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M31.64,2.25H4.83c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h26.81c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M31.64,5.95H5.12c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h26.53c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M35.93,23.88h-7.25c-1.87,0-3.39-1.52-3.39-3.39v-4.88c0-1.87,1.52-3.39,3.39-3.39h7.25c1.87,0,3.39,1.52,3.39,3.39v4.88c0,1.87-1.52,3.39-3.39,3.39ZM28.68,14.47c-.63,0-1.14.51-1.14,1.14v4.88c0,.63.51,1.14,1.14,1.14h7.25c.63,0,1.14-.51,1.14-1.14v-4.88c0-.63-.51-1.14-1.14-1.14h-7.25Z"
        fill={props.color}
      />
      <path
        d="M30.48,20.36c-.62,0-1.12-.5-1.12-1.12v-2.37c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v2.37c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M30.55,28.72H4.68C2.1,28.72,0,26.62,0,24.05V4.83C0,4.21.5,3.7,1.13,3.7s1.12.5,1.12,1.12v19.22c0,1.34,1.09,2.43,2.43,2.43h25.87c1.34,0,2.43-1.09,2.43-2.43v-1.29c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v1.29c0,2.58-2.1,4.68-4.68,4.68Z"
        fill={props.color}
      />
      <path
        d="M34.1,14.47c-.62,0-1.12-.5-1.12-1.12v-1.27c0-1.34-1.09-2.43-2.43-2.43H4.83c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h25.72c2.58,0,4.68,2.1,4.68,4.68v1.27c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Wallet;
