import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Family from "./icons/Family";
import Wallet from "./icons/Wallet";
import Education from "./icons/Education";
import Settings from "./icons/Settings";
import EditProfileIcn from "./icons/EditProfileIcn";
import Logout from "./icons/Logout";
import Timeline from "./icons/Timeline";
import { SENIOR_NOTIFICATIONS_LIST, SESSSION_OUT } from "../../../../Utils/constants";
import { BASE_URL } from "../../../../Utils/url";
import Notification from "./icons/settings/Notifications";
import Protection from "./icons/Protection";
import Insights from "./icons/Insights";
import Piggy from "./icons/Piggy";
import NotificationsSenior from "./icons/settings/NotificationsSenior";
import SeniorDashboard from "./icons/settings/SeniorDashboard";
import Budgets from "./icons/Budgets";
import {Shopping as ShoppingIcon} from "./icons/Shopping";
import Bills from "./icons/Bills";
import Caregiver from "./icons/Caregiver";
import { isFeatureDisabled, isPermission } from "../../../../Utils";
import FamilyPeople from "./icons/settings/FamilyPeople";
//import pkg from '../../../../../package.json'

const SeniorSidebar = (props) => {
  const userId = localStorage.getItem('seniorId');
  const { t } = useTranslation();
  const location = useLocation();
  const isBank = !props.showHeader || sessionStorage.getItem("__bank");
  const [notificationList, setNotificationsList] = useState([]);
  const activeColor = props.state.theme.values.primaryColor;
  const defaultColor = props.state.theme.values.tertiaryGrayColor;

  useEffect(() => {
    getNotificationsList();
  }, []);

  const getNotificationsList = () => {
    props._list(SENIOR_NOTIFICATIONS_LIST(userId), null, (res) => {
      const unreadNotifications = res.insights && res.insights.length && res.insights.filter(e => e.hasBeenDisplayed == false);
      setNotificationsList(unreadNotifications);
    })
  }

  const logout = () => {
    SESSSION_OUT(props);
  };

  const toggleSidebar = () => {
    document.querySelector('.parent-left-sidebar').classList.toggle('active');
  }

  return (
    <div className="parent-left-sidebar">
      <ul>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <FamilyPeople {...props} color={location.pathname === "/parent/dashboard" ? activeColor : defaultColor} />
              </span>
            </span>
            <span>{t('my_family')}</span>
          </Link>
        </li>
        {props?.state?.user?.self?.userType =='Senior' && isPermission(props,'Seniors') === true && 
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/senior/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <SeniorDashboard {...props} color={location.pathname === "/parent/senior/dashboard" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('My Dashboard')}</span>
          </Link>
        </li>
        }
        {props?.state?.user?.self?.userType !='Senior' && isPermission(props,'Seniors') === true && 
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/dashboard" ? 'active' : ''}`}>
          <Link to="/parent/senior/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <SeniorDashboard {...props} color={location.pathname === "/parent/senior/dashboard" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Dashboard')}</span>
          </Link>
        </li>
        }
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/notifications" ? 'active' : ''}`}>
          <Link to="/parent/senior/notifications">
            <span className="menu-icon">
              <span className="svgIcon">
                {notificationList.length > 0 && <span className="notificationCount">{notificationList.length}</span>}
                <NotificationsSenior {...props} color={location.pathname === "/parent/senior/notifications" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Notifications')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/accounts" ? 'active' : ''}`}>
          <Link to="/parent/senior/accounts">
            <span className="menu-icon">
              <span className="svgIcon">
                <Piggy {...props} color={location.pathname === "/parent/senior/accounts" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Accounts')}</span>
          </Link>
        </li>
        {/* <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/budget" ? 'active' : ''}`}>
          <Link to="/parent/senior/budget">
            <span className="menu-icon">
              <span className="svgIcon">
                <Budgets {...props}/>
              </span>
            </span>
            <span>{t('Budget')}</span>
          </Link>
        </li> */}
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/spending" ? 'active' : ''}`}>
          <Link to="/parent/senior/spending">
            <span className="menu-icon">
              <span className="svgIcon">
                <ShoppingIcon color={location.pathname === "/parent/senior/spending" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Spending')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/recurringBills" ? 'active' : ''}`}>
          <Link to="/parent/senior/recurringBills">
            <span className="menu-icon">
              <span className="svgIcon">
                <Bills {...props} color={location.pathname === "/parent/senior/recurringBills" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Bills')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/protection" ? 'active' : ''}`}>
          <Link to="/parent/senior/protection">
            <span className="menu-icon">
              <span className="svgIcon">
                <Protection {...props} color={location.pathname === "/parent/senior/protection" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Protections')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/financialCaregivers" ? 'active' : ''}`}>
          <Link to="/parent/senior/financialCaregivers">
            <span className="menu-icon">
              <span className="svgIcon">
                <Caregiver {...props} color={location.pathname === "/parent/senior/financialCaregivers" ? activeColor : defaultColor} />
              </span>
            </span>
            <span>{t('Caregivers')}</span>
          </Link>
        </li>

        
        {/* <li onClick={toggleSidebar} className={`${location.pathname === "/parent/senior/settings" ? 'active' : ''}`}>
          <Link to="/parent/senior/settings">
            <span className="menu-icon">
              <span className="svgIcon">
                <Settings {...props} />
              </span>
            </span>
            <span>{t('settings')}</span>
          </Link>
        </li> */}

        <li onClick={toggleSidebar} className={`${location.pathname === "/parent/logout" ? 'active' : ''}`}>
          <a href="#logout" onClick={logout}>
            <span className="menu-icon">
              <span className="svgIcon">
                <Logout {...props} color={location.pathname === "/parent/logout" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('sign_out')}</span>
          </a>
        </li>
        {/* <li style={{marginLeft: `40px`}}>
            v{pkg.version}
          </li> */}
      </ul>
    </div>
  );
};

export default SeniorSidebar;
