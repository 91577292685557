import React, {useState, useEffect} from "react";
import { useLocation } from "react-router";
import { isPermission, isFeatureDisabled } from "../../../../Utils";
import Family from "../../../Authenticated/Components/Sidebar/icons/Family";
import Education from "../../../Authenticated/Components/Sidebar/icons/Education";
import { Shopping } from "../../../Authenticated/Components/Sidebar/icons/Shopping";
import QrCode from "./icons/QrCode";
import Chores from "./icons/Chores";
import Timeline from "../../../Authenticated/Components/Sidebar/icons/Timeline";
import Research from "../../../Authenticated/Components/Sidebar/icons/Research";
import Watchlist from "../../../Authenticated/Components/Sidebar/icons/Watchlist";
import Report from "../../../Authenticated/Components/Sidebar/icons/Report";
import InvestmentDashboard from "../../../Authenticated/Components/Sidebar/icons/InvestmentDashboard";

const Bottom = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;
  const location = useLocation();
  const isVisible = props.isMobileNav;
  const [themeLoaded,setthemeLoaded] = useState(false);
  const activeColor = props.state.theme.values.primaryWardColor;
  const defaultColor = props.state.theme.values.tertiaryGrayColor;

  useEffect(()=>{
    if(props && props.state && props.state.theme.values){
      setthemeLoaded(true);
    }
  },[props && props.state])

  return (
    <>
      {props.investmentSidebar === false ?
        <div className={`bottom-navigation-bar ${isVisible ? '' : 'hide'}`}>
          <div className="inner-bar">
            <ul>
              <li onClick={() => props.history.push('/child/dashboard')} className={`${location.pathname === "/child/dashboard" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Family color={location.pathname === "/child/dashboard" ? activeColor : defaultColor} />
                </span>
                <span className="half-circle"></span>
              </li>
              {isFeatureDisabled(props,'Goals') === false && isFeatureDisabled(props,'Chores') === false ? null :   
              <li onClick={() => props.history.push('/child/chores')} className={`${location.pathname === "/child/chores" ? 'active' : ''}`}>
                {props.chore.pending > 0 ?
                  <div className="badge">{props.chore.pending}</div>
                  : null}
                <span className="svgIcon">
                  <Chores color={location.pathname === "/child/chores" ? activeColor : defaultColor} />
                </span>
                <span className="half-circle"></span>
              </li>
              }
              {isFeatureDisabled(props,'Education') === true &&
              <li onClick={() => props.history.push('/child/education')} className={`${location.pathname === "/child/education" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Education color={location.pathname === "/child/education" ? activeColor : defaultColor} />
                </span>
                <span className="half-circle"></span>
              </li>
              }
              <li onClick={() => props.history.push('/child/shopping')} className={`${location.pathname === "/child/shopping" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Shopping color={location.pathname === "/child/shopping" ? activeColor : defaultColor} />
                </span>
                <span className="half-circle"></span>
              </li>
              {themeLoaded && isFeatureDisabled(props,'DirectPay') === true && sessionStorage.getItem('showMazoolaPay') &&
                <li onClick={() => props.history.push('/child/pay')} className={`${location.pathname === "/child/pay" ? 'active' : ''}`}>
                  <span className="svgIcon">
                    <QrCode color={location.pathname === "/child/pay" ? activeColor : defaultColor} />
                  </span>
                  <span className="half-circle"></span>
                </li>
              }
            </ul>
          </div>
        </div>
        :

        <div className={`bottom-navigation-bar ${isVisible ? '' : 'hide'}`}>
          <div className="inner-bar">
            <ul>
              <li onClick={() => props.history.push('/child/investments/dashboard')} className={`${location.pathname === "/child/investments/dashboard" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <InvestmentDashboard color={location.pathname === "/child/investments/dashboard" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/child/investments/timeline')} className={`${location.pathname === "/child/investments/timeline" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Timeline color={location.pathname === "/child/investments/timeline" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/child/investments/research')} className={`${location.pathname === "/child/investments/research" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Research color={location.pathname === "/child/investments/research" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/child/investments/watchlist')} className={`${location.pathname === "/child/investments/watchlist" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Watchlist color={location.pathname === "/child/investments/watchlist" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
              <li onClick={() => props.history.push('/child/investments/reports')} className={` ${location.pathname === "/child/investments/reports" ? 'active' : ''}`}>
                <span className="svgIcon">
                  <Report color={location.pathname === "/child/investments/reports" ? activeColor : defaultColor}/>
                </span>
                <span className="half-circle"></span>
              </li>
            </ul>
          </div>
        </div>
      }
    </>
  );
};

export default Bottom;
