import React from "react";

const TransactionBankFee = (props) => {
  return (
    <svg
      viewBox="0 0 104 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 61C7.89543 61 7 61.8954 7 63V69H2C0.895431 69 0 69.8954 0 71V78C0 79.1046 0.895432 80 2 80H102C103.105 80 104 79.1046 104 78V71C104 69.8954 103.105 69 102 69H98V63C98 61.8954 97.1046 61 96 61H9Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 24C7 25.1046 7.89543 26 9 26H15V57H25V26H33V53.9411C33 56.4451 35.8866 57.8472 37.8548 56.299L40.649 54.1011L44.4475 56.3984C45.402 56.9757 46.598 56.9757 47.5525 56.3984L51.5 54.011L55.4475 56.3984C56.402 56.9757 57.598 56.9757 58.5525 56.3984L62.351 54.1011L65.1452 56.299C67.1134 57.8472 70 56.4452 70 53.9411V26H79V57H90V26H94C95.1046 26 96 25.1046 96 24V16.6667C96 15.8098 95.4541 15.0481 94.6427 14.7727L51.4224 0.106078C50.9988 -0.0376611 50.5392 -0.0352588 50.1172 0.112901L8.33754 14.7796C7.53627 15.0609 7 15.8175 7 16.6667V24ZM37 51.8822V12H66V51.8822L64.2803 50.5295C63.2987 49.7574 61.9416 49.6741 60.873 50.3203L57 52.6627L53.0525 50.2753C52.098 49.698 50.902 49.698 49.9475 50.2753L46 52.6627L42.127 50.3203C41.0584 49.6741 39.7013 49.7574 38.7197 50.5295L37 51.8822Z"
        fill={props.color}
      />
      <path
        d="M50.731 46.86V29H52.631V46.86H50.731ZM51.415 44.808C50.351 44.808 49.3313 44.6687 48.356 44.39C47.3807 44.0987 46.5953 43.725 46 43.269L47.045 40.951C47.615 41.3563 48.2863 41.692 49.059 41.958C49.8443 42.2113 50.636 42.338 51.434 42.338C52.042 42.338 52.5297 42.281 52.897 42.167C53.277 42.0403 53.5557 41.8693 53.733 41.654C53.9103 41.4387 53.999 41.1917 53.999 40.913C53.999 40.5583 53.8597 40.2797 53.581 40.077C53.3023 39.8617 52.935 39.6907 52.479 39.564C52.023 39.4247 51.5163 39.298 50.959 39.184C50.4143 39.0573 49.8633 38.9053 49.306 38.728C48.7613 38.5507 48.261 38.3227 47.805 38.044C47.349 37.7653 46.9753 37.398 46.684 36.942C46.4053 36.486 46.266 35.9033 46.266 35.194C46.266 34.434 46.4687 33.7437 46.874 33.123C47.292 32.4897 47.9127 31.9893 48.736 31.622C49.572 31.242 50.617 31.052 51.871 31.052C52.707 31.052 53.5303 31.1533 54.341 31.356C55.1517 31.546 55.8673 31.8373 56.488 32.23L55.538 34.567C54.9173 34.2123 54.2967 33.9527 53.676 33.788C53.0553 33.6107 52.4473 33.522 51.852 33.522C51.2567 33.522 50.769 33.5917 50.389 33.731C50.009 33.8703 49.7367 34.054 49.572 34.282C49.4073 34.4973 49.325 34.7507 49.325 35.042C49.325 35.384 49.4643 35.6627 49.743 35.878C50.0217 36.0807 50.389 36.2453 50.845 36.372C51.301 36.4987 51.8013 36.6253 52.346 36.752C52.9033 36.8787 53.4543 37.0243 53.999 37.189C54.5563 37.3537 55.063 37.5753 55.519 37.854C55.975 38.1327 56.3423 38.5 56.621 38.956C56.9123 39.412 57.058 39.9883 57.058 40.685C57.058 41.4323 56.849 42.1163 56.431 42.737C56.013 43.3577 55.386 43.858 54.55 44.238C53.7267 44.618 52.6817 44.808 51.415 44.808Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 18C61 19.1046 60.1046 20 59 20L43 20C41.8954 20 41 19.1046 41 18C41 16.8954 41.8954 16 43 16L59 16C60.1046 16 61 16.8954 61 18Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 25C53 26.1046 52.1046 27 51 27H43C41.8954 27 41 26.1046 41 25C41 23.8954 41.8954 23 43 23H51C52.1046 23 53 23.8954 53 25Z"
        fill={props.color}
      />
    </svg>
  );
};

export default TransactionBankFee;
