import React from "react";

const Timeline = (props) => {

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.22 33.22"
    >
      <path
        d="M16.61,33.22C7.45,33.22,0,25.77,0,16.61S7.45,0,16.61,0s16.61,7.45,16.61,16.61-7.45,16.61-16.61,16.61ZM16.61,2.25c-7.92,0-14.36,6.44-14.36,14.36s6.44,14.36,14.36,14.36,14.36-6.44,14.36-14.36S24.52,2.25,16.61,2.25Z"
        fill={props.color}
      />
      <path
        d="M20.65,24.46c-.29,0-.58-.11-.8-.33l-4.71-4.71c-.21-.21-.33-.5-.33-.8v-9.42c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v8.96l4.38,4.38c.44.44.44,1.15,0,1.59-.22.22-.51.33-.8.33Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Timeline;
