import React from "react";

const Protection = (props) => {
  return (
    <svg
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.44 34.44"
    >
      <path
        d="M14.79,23.12c-.29,0-.57-.11-.79-.32l-4.97-4.9c-.44-.44-.45-1.15-.01-1.59.44-.44,1.15-.45,1.59-.01l4.18,4.13,9.05-8.79c.45-.43,1.16-.42,1.59.02.43.45.42,1.16-.02,1.59l-9.84,9.56c-.22.21-.5.32-.78.32Z"
        fill={props.color}
      />
      <path
        d="M17.22,34.44c-.09,0-.18-.01-.26-.03C6.22,31.84.53,23.47.04,9.53,0,8.29,0,7.18,0,6.28,0,5.65.5,5.15,1.13,5.15c5.74,0,10.79-1.62,15.44-4.94.39-.28.92-.28,1.31,0,4.65,3.33,9.71,4.94,15.44,4.94.62,0,1.12.5,1.12,1.12,0,.91,0,2.01-.04,3.26-.49,13.93-6.18,22.3-16.91,24.88-.09.02-.17.03-.26.03ZM2.25,7.38c0,.63.01,1.33.04,2.07.45,12.86,5.33,20.29,14.93,22.7,9.59-2.41,14.48-9.84,14.93-22.7.03-.75.04-1.44.04-2.07-5.5-.19-10.41-1.8-14.97-4.89C12.66,5.58,7.75,7.19,2.25,7.38Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Protection;
