import React from "react";

const CreditCard = (props) => {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 16.3C3 14.613 3.0878 13.3229 3.29964 12.3278C3.50956 11.3418 3.8277 10.7113 4.24219 10.2786C5.0838 9.39999 6.64434 9 9.95053 9H29.0495C32.3557 9 33.9162 9.39999 34.7578 10.2786C35.1723 10.7113 35.4904 11.3418 35.7004 12.3278C35.9122 13.3229 36 14.613 36 16.3V24.7C36 26.387 35.9122 27.6771 35.7004 28.6722C35.4904 29.6582 35.1723 30.2887 34.7578 30.7214C33.9162 31.6 32.3557 32 29.0495 32H9.95053C6.64434 32 5.0838 31.6 4.24219 30.7214C3.8277 30.2887 3.50956 29.6582 3.29964 28.6722C3.0878 27.6771 3 26.387 3 24.7V16.3Z"
        stroke={props.color}
        strokeWidth="2"
      />
      <rect x="7" y="23" width="7" height="2" rx="1" fill={props.color} />
      <rect x="7" y="26" width="13" height="2" rx="1" fill={props.color} />
      <rect x="3" y="15" width="34" height="4" fill={props.color} />
    </svg>
  );
};

export default CreditCard;
