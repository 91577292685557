import React from "react";

const SeniorDashboard = (props) => {
  return (
    <svg
      viewBox="0 0 32 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={props.color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 1.99994H4C2.89543 1.99994 2 2.89537 2 3.99994V19.9999C2 21.1045 2.89543 21.9999 4 21.9999H28C29.1046 21.9999 30 21.1045 30 19.9999V3.99994C30 2.89537 29.1046 1.99994 28 1.99994ZM4 -6.10352e-05C1.79086 -6.10352e-05 0 1.7908 0 3.99994V19.9999C0 22.2091 1.79086 23.9999 4 23.9999H28C30.2091 23.9999 32 22.2091 32 19.9999V3.99994C32 1.7908 30.2091 -6.10352e-05 28 -6.10352e-05H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.29527 6.99994H6.70703L6.70703 8.99994H9.29527L9.29527 6.99994ZM6.70703 4.99994C5.60246 4.99994 4.70703 5.89537 4.70703 6.99994V8.99994C4.70703 10.1045 5.60246 10.9999 6.70703 10.9999H9.29527C10.3998 10.9999 11.2953 10.1045 11.2953 8.99994V6.99994C11.2953 5.89537 10.3998 4.99994 9.29527 4.99994H6.70703Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2953 14.9999H22.707L22.707 16.9999H25.2953L25.2953 14.9999ZM22.707 12.9999C21.6025 12.9999 20.707 13.8954 20.707 14.9999V16.9999C20.707 18.1045 21.6025 18.9999 22.707 18.9999H25.2953C26.3998 18.9999 27.2953 18.1045 27.2953 16.9999V14.9999C27.2953 13.8954 26.3998 12.9999 25.2953 12.9999H22.707Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2934 6.99994H15.1758L15.1758 8.99994H25.2934L25.2934 6.99994ZM15.1758 4.99994C14.0712 4.99994 13.1758 5.89537 13.1758 6.99994V8.99994C13.1758 10.1045 14.0712 10.9999 15.1758 10.9999H25.2934C26.398 10.9999 27.2934 10.1045 27.2934 8.99994V6.99994C27.2934 5.89537 26.398 4.99994 25.2934 4.99994H15.1758Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.8247 14.9999H6.70703L6.70703 16.9999H16.8247L16.8247 14.9999ZM6.70703 12.9999C5.60246 12.9999 4.70703 13.8954 4.70703 14.9999V16.9999C4.70703 18.1045 5.60246 18.9999 6.70703 18.9999H16.8247C17.9292 18.9999 18.8247 18.1045 18.8247 16.9999V14.9999C18.8247 13.8954 17.9292 12.9999 16.8247 12.9999H6.70703Z"
      />
    </svg>
  );
};

export default SeniorDashboard;
