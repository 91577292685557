import React from "react";

const Chores = (props) => {

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.83 29.44"
    >
      <path
        d="M18.6,29.45c-.25,0-.51,0-.76,0h-5.92c-1.94.05-3.93-.11-5.87-.46-1.32-.22-2.56-.82-3.56-1.73-1-1.07-1.62-2.33-1.85-3.69-.35-1.91-.51-3.9-.46-5.87v-5.94c-.05-1.97.11-3.95.46-5.88.21-1.33.81-2.58,1.73-3.59.02-.03.05-.05.08-.08,1.01-.92,2.27-1.52,3.62-1.74,1.91-.35,3.89-.51,5.86-.46h5.95c1.95-.05,3.93.11,5.87.46,1.32.22,2.56.82,3.56,1.73,1,1.07,1.62,2.33,1.85,3.69.36,1.92.51,3.9.46,5.87v5.95c.05,1.94-.1,3.92-.46,5.87-.22,1.32-.82,2.56-1.73,3.56-1.07,1-2.33,1.62-3.69,1.85-1.68.31-3.42.47-5.15.47ZM17.87,27.19c1.86.05,3.69-.09,5.49-.43.94-.16,1.79-.57,2.48-1.21.56-.61.96-1.45,1.11-2.36.33-1.81.48-3.63.43-5.45v-6.01c.05-1.84-.1-3.67-.43-5.46-.16-.94-.57-1.79-1.21-2.48-.61-.56-1.45-.96-2.36-1.11-1.8-.33-3.63-.48-5.45-.43h-6.01c-1.84-.04-3.67.1-5.46.43-.93.15-1.77.54-2.45,1.15-.61.68-1,1.52-1.14,2.42-.33,1.81-.48,3.64-.43,5.46v6.01c-.05,1.85.1,3.67.43,5.46.16.94.57,1.79,1.21,2.48.61.56,1.45.96,2.36,1.11,1.8.33,3.63.48,5.45.43h5.97Z"
        fill={props.color}
      />
      <path
        d="M13.03,20.67h0c-.3,0-.58-.12-.8-.33l-4.3-4.33c-.44-.44-.44-1.15,0-1.59.44-.44,1.15-.44,1.59,0l3.5,3.53,7.73-7.73c.44-.44,1.15-.44,1.59,0,.44.44.44,1.15,0,1.59l-8.53,8.53c-.21.21-.5.33-.8.33Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Chores;
