import React from "react";

const Logout = (props) => {
  const iconColor = props.state?.theme?.values?.tertiaryGrayColor;

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.32 28.32"
    >
      <path
        d="M9.81,28.32h-5.79C1.8,28.32,0,26.51,0,24.29V4.02C0,1.8,1.8,0,4.02,0h5.79c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12h-5.79c-.98,0-1.77.79-1.77,1.77v20.27c0,.98.79,1.77,1.77,1.77h5.79c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M19.95,22.52c-.29,0-.58-.11-.8-.33-.44-.44-.44-1.15,0-1.59l6.45-6.44-6.45-6.44c-.44-.44-.44-1.15,0-1.59.44-.44,1.15-.44,1.59,0l7.24,7.24c.44.44.44,1.15,0,1.59l-7.24,7.24c-.22.22-.51.33-.8.33Z"
        fill={props.color}
      />
      <path
        d="M27.19,15.28H9.81c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h17.38c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Logout;
