import React from "react";

const Education = (props) => {

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44.24 30.52"
    >
      <path
        d="M22.32,30.52c-7.1,0-12.67-3.72-12.67-8.47,0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12c0,3.37,4.77,6.22,10.42,6.22s10.42-2.85,10.42-6.22c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12c0,4.75-5.56,8.47-12.67,8.47Z"
        fill={props.color}
      />
      <path
        d="M33.87,23.17c-.62,0-1.12-.5-1.12-1.12v-5.82c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v5.82c0,.62-.5,1.12-1.12,1.12ZM10.78,23.17c-.62,0-1.12-.5-1.12-1.12v-5.82c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v5.82c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M22.12,22.37c-.25,0-.5-.05-.73-.16L1,12.76C.38,12.47,0,11.86,0,11.18c0-.68.39-1.28,1-1.57L21.39.16c.47-.22,1-.21,1.46,0l20.39,9.45c.62.29,1,.89,1,1.57,0,.68-.38,1.28-1,1.57l-20.4,9.46c-.23.11-.48.16-.73.16ZM21.9,20.17s0,0,0,0h0ZM2.96,11.18l19.16,8.88,19.16-8.88L22.12,2.3,2.96,11.18ZM42.3,11.65h0,0ZM21.9,2.2s0,0,0,0h0Z"
        fill={props.color}
      />
      <path
        d="M40.74,23.76c-.55,0-1.06-.32-1.29-.83l-.66-1.44c-.17-.37-.17-.8,0-1.18l.66-1.44s0,0,0,0c.23-.5.73-.83,1.29-.83s1.06.32,1.29.83l.66,1.44c.17.37.17.8,0,1.18l-.66,1.43c-.23.5-.74.83-1.29.83Z"
        fill={props.color}
      />
      <path
        d="M40.74,19.9c-.6,0-1.09-.49-1.09-1.09v-6.02c0-.6.49-1.09,1.09-1.09s1.09.49,1.09,1.09v6.02c0,.6-.49,1.09-1.09,1.09Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Education;
