import React from "react";

const InfoCircle = (props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="21"
      width={props.width}
      viewBox="0 0 24 24"
      className="pointer"
    >
      <g
        strokeLinecap="round"
        strokeWidth="2"
        fill="none"
        stroke={props.color}
        strokeLinejoin="round"
        className="nc-icon-wrapper"
      >
        <circle cx="12" cy="12" r="11" />
        <line x1="12" y1="11" x2="12" y2="17" stroke={props.color} />
        <circle cx="12" cy="7" r="1" stroke="none" fill={props.color} />
      </g>
    </svg>
  );
};

export default InfoCircle;
