import React from "react";

const Bills = (props) => {
  return (
    <svg
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 27.74 31.81"
    >
      <path
        d="M25.23,31.81H2.54C1.16,31.81.03,30.68.03,29.3V2.55C.03,1.17,1.16.04,2.54.04h22.69c1.38,0,2.51,1.13,2.51,2.51v26.75c0,1.38-1.13,2.51-2.51,2.51ZM2.54,2.29c-.14,0-.26.12-.26.26v26.75c0,.14.12.26.26.26h22.69c.14,0,.26-.12.26-.26V2.55c0-.14-.12-.26-.26-.26H2.54Z"
        fill={props.color}
      />
      <path
        d="M21.4,7.65H6.32c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h15.08c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M14.72,15.69H6.32c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h8.4c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M14.72,21.05H6.32c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h8.4c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M14.72,26.41H6.32c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h8.4c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M21.4,15.69h-2.44c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h2.44c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M21.4,21.05h-2.44c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h2.44c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M21.4,26.41h-2.44c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h2.44c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Bills;
