import React from "react";

const NotificationsSenior = (props) => {
  return (
    <svg
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33.33 36.34"
    >
      <path
        d="M32.08,29.94H1.25c-.49,0-.93-.28-1.13-.72-.2-.44-.13-.96.18-1.33,2.67-3.14,4.06-7.71,4.14-13.56,0-6.34,5.51-11.44,12.28-11.44,7.4,0,12.18,4.5,12.18,11.45,0,6.4,2.14,11.19,4.14,13.55.32.37.39.89.18,1.33-.21.44-.65.72-1.13.72ZM3.69,27.44h25.99c-1.76-2.99-3.28-7.53-3.28-13.11,0-6.6-5-8.95-9.68-8.95-5.39,0-9.78,3.98-9.78,8.86v.11c-.07,5.31-1.16,9.7-3.24,13.09Z"
        fill={props.color}
      />
      <path
        d="M16.71,36.34c2.72,0,4.89-1.99,4.89-4.55h-9.86c0,2.56,2.17,4.55,4.97,4.55Z"
        fill={props.color}
      />
      <path
        d="M19.11,4.13v-1.86c0-1.21-1.09-2.28-2.41-2.28-1.4,0-2.48,1.07-2.48,2.28v1.86h4.89Z"
        fill={props.color}
      />
    </svg>
  );
};

export default NotificationsSenior;
