import React from "react";

const Piggy = (props) => {
  return (
    <svg
      id="Isolation_Mode"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36.96 31.72"
    >
      <path
        d="M33.26,27.87H3.7c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h29.57c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M35.83,31.72H1.12c-.62,0-1.12-.5-1.12-1.12s.5-1.12,1.12-1.12h34.71c.62,0,1.12.5,1.12,1.12s-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M33.26,10.87H3.7c-.62,0-1.12-.5-1.12-1.12v-4.21c0-.5.33-.94.8-1.08L18.16.05c.21-.06.43-.06.64,0l14.78,4.41c.48.14.8.58.8,1.08v4.21c0,.62-.5,1.12-1.12,1.12ZM4.82,8.62h27.32v-2.25l-13.66-4.07L4.82,6.37v2.25Z"
        fill={props.color}
      />
      <path
        d="M14.96,24.01c-.62,0-1.12-.5-1.12-1.12v-13.14c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v13.14c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M22,24.01c-.62,0-1.12-.5-1.12-1.12v-13.14c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v13.14c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M7.93,24.01c-.62,0-1.12-.5-1.12-1.12v-13.14c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v13.14c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
      <path
        d="M29.03,24.01c-.62,0-1.12-.5-1.12-1.12v-13.14c0-.62.5-1.12,1.12-1.12s1.12.5,1.12,1.12v13.14c0,.62-.5,1.12-1.12,1.12Z"
        fill={props.color}
      />
    </svg>
  );
};

export default Piggy;
