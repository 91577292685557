import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Family from "./icons/Family";
import InvestmentDashboard from "./icons/InvestmentDashboard";
import Report from "./icons/Report";
import Research from "./icons/Research";
import Watchlist from "./icons/Watchlist";
import Timeline from "./icons/Timeline";
import Logout from "./icons/Logout";
import CreditCard from "./icons/CreditCard";
import { SESSSION_OUT } from "../../../../Utils/constants";

const InvestmentSidebar = (props) => {

  const { t } = useTranslation();
  const location = useLocation();
  const isBank = localStorage.getItem("__bank");
  const activeColor = props.state.theme.values.primaryWardColor;
  const defaultColor = props.state.theme.values.tertiaryGrayColor;

  const logout = () => {
    document.querySelectorAll(`body`).forEach(e => e.classList.remove(`child-module`));
    document.querySelectorAll(`body`).forEach(e => e.classList.remove(`practice-mode`));
    SESSSION_OUT(props);
  };

  const toggleSidebar = () => {
    document.querySelector('.child-left-sidebar').classList.toggle('active');
  }

  return (
    <div className="child-left-sidebar">
      <ul>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/dashboard" ? 'active' : ''}`}>
          <Link to="/child/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <CreditCard {...props} color={location.pathname === "/child/dashboard" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('Home')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/investments/dashboard" ? 'active' : ''}`}>
          <Link to="/child/investments/dashboard">
            <span className="menu-icon">
              <span className="svgIcon">
                <InvestmentDashboard {...props} color={location.pathname === "/child/investments/dashboard" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('investments')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/investments/timeline" ? 'active' : ''}`}>
          <Link to="/child/investments/timeline">
            <span className="menu-icon">
              <span className="svgIcon">
                <Timeline {...props} color={location.pathname === "/child/investments/timeline" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('timeline')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/investments/research" || location.pathname === "/child/investments/research-industry" ? 'active' : ''}`}>
          <Link to="/child/investments/research">
            <span className="menu-icon">
              <span className="svgIcon">
                <Research {...props} color={location.pathname === "/child/investments/research" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('research')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/investments/watchlist" ? 'active' : ''}`}>
          <Link to="/child/investments/watchlist">
            <span className="menu-icon">
              <span className="svgIcon">
                <Watchlist {...props} color={location.pathname === "/child/investments/watchlist" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('investment_watchlist.watchlist')}</span>
          </Link>
        </li>
        <li onClick={toggleSidebar} className={`${location.pathname === "/child/investments/reports" ? 'active' : ''}`}>
          <Link to="/child/investments/reports">
            <span className="menu-icon">
              <span className="svgIcon">
                <Report {...props} color={location.pathname === "/child/investments/reports" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('reports')}</span>
          </Link>
        </li>

        {!isBank && 
        <li onClick={toggleSidebar} className={`${location.pathname === "/dashboard" ? 'active' : ''}`}>
          <a href="#logout" onClick={logout}>
            <span className="menu-icon">
              <span className="svgIcon">
                <Logout {...props} color={location.pathname === "/dashboard" ? activeColor : defaultColor}/>
              </span>
            </span>
            <span>{t('sign_out')}</span>
          </a>
        </li>
        }
      </ul>
    </div>
  );
};

export default InvestmentSidebar;
